import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

interface MobileContextSchema {
  isMobile: boolean;
}

const MobileContext = createContext<MobileContextSchema>({
  isMobile: false,
});

export function MobileProvider({ children }: { children: ReactNode }) {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(window.innerWidth < 1024);
    const handleResize = () => setIsMobile(window.innerWidth < 1024);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isMobile]);

  return <MobileContext.Provider value={{ isMobile }}>{children}</MobileContext.Provider>;
}

export function useMobile() {
  return useContext(MobileContext);
}
