import { useLoading } from '@context/LoadingContext';
import useApi from '@hooks/useApi';
import braintree from 'braintree';
import React, { useEffect, useState } from 'react';
import CurrentSubscription from './components/CurrentSubscription';
import ModifySubscription from './components/ModifySubscription';

const SubscriptionPage: React.FC = () => {
  const { loading } = useApi();
  const { setIsLoading } = useLoading();
  const [modifySubscription, setModifySubscription] = useState<boolean>(false);
  const [subscription, setSubscription] = useState<braintree.Subscription | undefined>(undefined);
  const [plans, setPlans] = useState<braintree.Plan[]>([]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading, setIsLoading]);

  /**
   * Close the modify subscription form and set the new subscription
   * 
   * @param {braintree.Subscription} subscription The new subscription
   */
  const handleOnSetSubscription = (subscription: braintree.Subscription) => {
    setModifySubscription(false);
    setSubscription(subscription);
  }

  return (
    <div className="container p-4">
      <div className="mx-auto w-3/4">
        {!modifySubscription && <CurrentSubscription onSetSubscription={setSubscription} onSetPlans={setPlans} onModifySubscription={() => setModifySubscription(true)} />}
        {modifySubscription && <ModifySubscription plans={plans} subscription={subscription} onSetSubscription={handleOnSetSubscription} />}
      </div>
    </div>
  );
};

export default SubscriptionPage;
