import { Button } from '@/components/ui/button';
import { Spinner } from '@/components/ui/spinner';
import { Textarea } from '@/components/ui/textarea';
import { Resume } from '@schema/resume.interface';
import { WandSparkles } from 'lucide-react';
import { LLMResponse } from '@schema/llm-response.interface';
import { useAuth } from '@context/AuthContext';
import { useError } from '@context/ErrorContext';
import useApi from '@hooks/useApi';

interface SetJobPostingComponentProps {
  masterResume?: string;
  onSetLLMResponse: (response: LLMResponse) => void;
}

const SetJobPostingComponent = ({ masterResume, onSetLLMResponse }: SetJobPostingComponentProps) => {
  const { user, setUser } = useAuth();
  const { loading, request } = useApi();
  const { setError } = useError();

  /**
   * Creates a tailored resume using the job post and the master resume.
   */
  const createTailoredResume = async () => {
    const jobPost = document.getElementById('job-posting') as HTMLTextAreaElement;

    if (!masterResume || !jobPost) {
      setError('Master resume or job post is missing!');
      return;
    }

    try {
      const response: Resume = await request({
        method: 'POST',
        url: '/api/resume/create',
        data: {
          masterResume: masterResume,
          jobPost: jobPost.value,
        },
        timeout: 2 * 60 * 1000, // 2 minutes
      }).catch((error) => {
        console.error(error);
        setError('Failed to create tailored resume. Please try again later.');
      });

      if (user) {
        setUser({ ...user, credits: response.creditsRemaining });
      }
      onSetLLMResponse(response.resume);
    } catch (error) {
      console.error(error);
      setError('Failed to create tailored resume. Please try again later.');
    }
  };

  return (
    <div className="space-y-4 bg-white p-8 shadow-lg rounded-lg flex flex-col flex-grow">
      <h2 className="text-2xl font-semibold">Job Posting</h2>
      <p className="text-sm text-gray-500">Copy and Paste the entire job post into this text area, then make some magic happen!</p>
      <div className="flex-grow flex">
        <Textarea id="job-posting" name="job-posting" className="h-full w-full" />
      </div>
      <Button onClick={createTailoredResume} disabled={loading} className="w-full">
        {loading ? (
          <>
            <Spinner className="mr-2 h-4 w-4" />
            Doing Magic...
          </>
        ) : (
          <>
            <WandSparkles className="mr-2 h-4 w-4" />
            Make some magic happen!
          </>
        )}
      </Button>
    </div>
  );
};

export default SetJobPostingComponent;
