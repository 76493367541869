import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

interface SidebarContextSchema {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  /**
   * The class name(s) to apply to the sidebar.
   */
  className?: string;
}

const SidebarContext = createContext<SidebarContextSchema>({
  visible: false,
  setVisible: () => void 0,
  className: 'w-64 right-0',
});

export const SidebarProvider = ({ children }: { children: ReactNode }) => {
  const [visible, setVisible] = useState(false);
  const [className, setClassName] = useState('w-64 right-0');

  /**
   * Set the class name based on the window size and visibility.
   */
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768 || !visible) {
        setClassName('w-full');
      } else {
        setClassName('lg:calc-width right-0');
      }
    };

    // Set initial className based on the current window size and visibility
    handleResize(); 

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [visible]);

  return <SidebarContext.Provider value={{ visible, setVisible, className }}>{children}</SidebarContext.Provider>;
};

export const useSidebar = () => useContext(SidebarContext);
