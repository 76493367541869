import useColorShift from '@hooks/useColorShift';
import { useRainbowGlow } from '@hooks/useRainbowGlow';
import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

interface CustomLinkProps extends LinkProps {
  colorShift?: boolean;
  index?: number;
  isDarkMode?: boolean;
  rainbowFlow?: boolean;
}

const CustomLink: React.FC<CustomLinkProps> = ({ children, colorShift = false, rainbowFlow = false, index = 0, isDarkMode = false, ...props }) => {
  const getColor = useColorShift();
  const colorStyle = colorShift ? { background: getColor(index, isDarkMode) } : {};
  const rainbowStyles = useRainbowGlow(5, 6, 5); // Adjusted blur and spread

  const baseClasses = 'relative text-white bg-gray-800 ml-2 px-3 py-2 rounded-md text-sm font-medium';

  if (rainbowFlow) {
    return (
      <Link
        {...props}
        style={{
          ...rainbowStyles.container,
          ...props.style,
          isolation: 'isolate', // Creates a new stacking context
        }}
        className={`${baseClasses} overflow-hidden`}>
        <span
          style={{
            ...rainbowStyles.glow,
            inset: '-2px', // Smaller inset to keep glow within boundaries
            borderRadius: 'inherit',
            opacity: 0.7,
          }}
        />
        <span
          style={{
            ...rainbowStyles.inner,
            position: 'absolute',
            inset: '1px',
            borderRadius: 'inherit',
            backgroundColor: 'inherit',
          }}
        />
        <span className="relative z-10">{children}</span>
      </Link>
    );
  } else if (colorShift) {
    return (
      <Link {...props} className={baseClasses} style={colorStyle}>
        {children}
      </Link>
    );
  } else {
    return (
      <Link {...props} className={baseClasses}>
        {children}
      </Link>
    );
  }
};

export default CustomLink;
