import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { useState } from 'react';

// Create styles
const styles = StyleSheet.create({
  tableCol: {
    fontSize: 12,
    fontWeight: 400,
    fontStyle: 'normal',
  },
  tableCol1: {
    width: '60%',
    flexGrow: 1,
  },
  tableCol2: {
    width: '40%',
    textAlign: 'right',
  },
});

const SpaceBetween = ({ col1, col2, fontSize, fontWeight, fontStyle }: { col1: string; col2: string; fontSize?: number; fontWeight?: number; fontStyle?: string }) => {
  const [updatedStyle] = useState<{
    fontSize: number;
    fontWeight: number;
    fontStyle: string;
  }>({
    fontSize: fontSize ? fontSize : 12,
    fontWeight: fontWeight ? fontWeight : 400,
    fontStyle: fontStyle ? fontStyle : 'normal',
  });

  return (
    <View style={{ flexDirection: 'row', width: '100%' }}>
      <Text style={[styles.tableCol, styles.tableCol1, updatedStyle]}>{col1}</Text>
      <Text style={[styles.tableCol, styles.tableCol2, updatedStyle]}>{col2}</Text>
    </View>
  );
};

export default SpaceBetween;
