import { useEffect, useState } from 'react';

const useColorShift = () => {
  const [colorOffset, setColorOffset] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setColorOffset((prevOffset) => (prevOffset + 1) % 360);
    }, 50);
    return () => clearInterval(timer);
  }, []);

  const getColor = (index: number, isDarkMode: boolean) => {
    const hue = (index * 90 + colorOffset) % 360;
    return `hsl(${hue}, 80%, ${isDarkMode ? '40%' : '60%'})`;
  };

  return getColor;
};

export default useColorShift;
