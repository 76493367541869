import { StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: 'white',
    padding: 20,
    fontFamily: 'Helvetica',
  },
  content: {
    width: '100%',
    flexGrow: 1,
  },
  title: {
    fontSize: 24,
    marginBottom: 10,
  },
  heading: {
    marginTop: 10,
    color: '#2c3e50',
    borderBottom: '1px solid #ccc',
  },
  li: {
    fontSize: 12,
    marginBottom: 5,
    paddingLeft: 10,
  },
  link: {
    fontSize: 11,
    textDecoration: 'none',
    textDecorationStyle: 'none',
  },
  text: {
    fontSize: 12,
    marginBottom: 10,
  },
  bold: {
    color: 'darkblue',
    fontStyle: 'bold',
    fontWeight: 900,
  },
});

export default styles;
