import { Button } from '@/components/ui/button';
import { Link } from 'react-router-dom';

const AboutPage = () => {
  return (
    <>
      <div className="bg-black min-h-[70vh]">
        <main className="flex flex-col items-center justify-center text-center px-4 py-12 sm:py-20">
          <h1 className="text-3xl sm:text-4xl md:text-5xl text-white font-bold mb-4">About Us</h1>
          <p className="text-lg sm:text-xl mb-8 text-white max-w-2xl">Our Story</p>
          <div className="w-full sm:w-3/4 md:w-2/3 lg:w-1/2 bg-white rounded mx-auto text-justify p-4 space-y-2">
            <p>Once upon a time, in a land of endless job boards and unanswered applications, there was a group of frustrated job seekers (okay, it was us) who decided enough was enough.</p>
            <p>
              <strong>We were tired</strong> of spending countless hours tailoring resumes, crafting cover letters, and coming up with clever icebreakers, only to be met with the deafening silence of
              the job-hunting void.
            </p>
            <p>So, we did what any reasonable group of sleep-deprived, coffee-fueled individuals would do: we created {process.env.REACT_APP_NAME}, the app that does all the hard work for you!</p>
          </div>
        </main>
      </div>
      <main className="container mx-auto px-4 py-12">
        <section className="bg-white rounded-lg shadow-md p-6 sm:p-8 mb-12 w-full sm:w-3/4 md:w-2/3 lg:w-1/2 mx-auto">
          <h3 className="text-xl sm:text-2xl font-bold mb-4 flex items-center justify-center">The Mission</h3>
          <p className="text-base sm:text-lg text-center">
            At {process.env.REACT_APP_NAME}, we&apos;re on a mission to make job hunting suck less. We can&apos;t guarantee you&apos;ll land your dream job, but we can promise you&apos;ll spend less
            time agonizing over application materials and more time doing literally anything else.
          </p>
          <div className="mt-8 text-center">
            <Link to="/login">
              <Button variant={'rainbowGlow'} size={'lg'} className="w-full sm:w-auto">
                Join the Revolution
              </Button>
            </Link>
          </div>
        </section>

        <section className="bg-white rounded-lg shadow-md p-6 sm:p-8 mb-12 w-full sm:w-3/4 md:w-2/3 lg:w-1/2 mx-auto">
          <h3 className="text-xl sm:text-2xl font-bold mb-4 flex items-center justify-center">Are you impressed?</h3>
          <p className="text-base sm:text-lg text-center">
            Impressed by what you see and want to hire me? <strong>I&apos;m available!</strong> Who knew creating an app to help people find jobs would lead to my own job opportunity? Life&apos;s
            funny like that.
          </p>
          <div className="mt-8 text-center">
            <a href="mailto:dave@rescueresume.ai">
              <Button variant={'rainbowGlow'} size={'lg'} className="w-full sm:w-auto">
                Let&apos;s Talk
              </Button>
            </a>
          </div>
        </section>
      </main>
    </>
  );
};

export default AboutPage;
