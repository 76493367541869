import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';

interface GoogleAnalyticsProps {
  trackingId?: string;
  children: React.ReactNode;
}

const GoogleAnalytics: React.FC<GoogleAnalyticsProps> = ({ trackingId, children }) => {
  useEffect(() => {
    if (trackingId) ReactGA.initialize(trackingId);
  }, [trackingId]);

  return <>{children}</>;
};

export default GoogleAnalytics;
