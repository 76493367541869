import { Link } from 'react-router-dom';
import LegalDocument from './components/LegalDocument';

const TermsOfUse = () => {
  return (
    <LegalDocument title="Terms of Use">
      <h2 className="text-2xl font-bold mb-4">1. Acceptance of Terms</h2>
      <p className="mb-4">By accessing or using {process.env.REACT_APP_NAME}, you agree to be bound by these Terms of Use. If you do not agree to these terms, please do not use our service.</p>

      <h2 className="text-2xl font-bold mb-4">2. Description of Service</h2>
      <p className="mb-4">{process.env.REACT_APP_NAME} provides an AI-powered platform for creating resumes, cover letters, and icebreaker messages tailored to specific job postings.</p>

      <h2 className="text-2xl font-bold mb-4">3. User Accounts</h2>
      <p className="mb-4">You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.</p>

      <h2 className="text-2xl font-bold mb-4">4. User Content</h2>
      <p className="mb-4">
        You retain all rights to the content you submit to {process.env.REACT_APP_NAME}. By submitting content, you grant us a license to use, modify, and distribute your content solely for the purpose of providing our
        service.
      </p>

      <h2 className="text-2xl font-bold mb-4">5. Prohibited Conduct</h2>
      <p className="mb-4">Users must not engage in any unlawful or fraudulent activities, or use the service to distribute spam or malicious content.</p>

      <h2 className="text-2xl font-bold mb-4">6. Termination</h2>
      <p className="mb-4">
        We reserve the right to terminate or suspend your account at our discretion, without notice, for conduct that we believe violates these Terms of Use or is harmful to other users, us, or third
        parties, or for any other reason.
      </p>

      <h2 className="text-2xl font-bold mb-4">7. Changes to Terms</h2>
      <p className="mb-4">We may modify these Terms of Use at any time. Continued use of {process.env.REACT_APP_NAME} after any such changes shall constitute your consent to such changes.</p>

      <h2 className="text-2xl font-bold mb-4">8. Contact Information</h2>
      <p className="mb-4">If you have any questions about these Terms of Use, please contact us at <Link to="mailto:legal@resumerescue.ai">legal@resumerescue.ai</Link>.</p>
    </LegalDocument>
  );
};

export default TermsOfUse;
