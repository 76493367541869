import { useAuth } from '@context/AuthContext';
import { useHistory } from '@context/HistoricalContext';
import { useLoading } from '@context/LoadingContext';
import useApi from '@hooks/useApi';
import CustomLink from '@pages/components/CustomLink';
import FormattedDateComponent from '@pages/components/FormattedDateComponent';
import { Resume } from '@schema/resume.interface';
import { Star, Trash2 } from 'lucide-react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

function Dashboard() {
  const { user } = useAuth();
  const { history, setHistory } = useHistory();
  const { loading, request } = useApi();
  const { setIsLoading } = useLoading();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const resumesPerPage = 5;

  // Calculate the indexes for slicing the history array
  const indexOfLastResume = currentPage * resumesPerPage;
  const indexOfFirstResume = indexOfLastResume - resumesPerPage;
  const currentResumes = history.slice(indexOfFirstResume, indexOfLastResume);

  // Calculate total pages
  const totalPages = Math.ceil(history.length / resumesPerPage);

  // Handle page change
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  /**
   * Set the loading state based on the API request.
   */
  useEffect(() => {
    setIsLoading(loading);
  }, [loading, setIsLoading]);

  /**
   * Mark a resume as favorite.
   *
   * @param {Resume} resume The resume to mark as favorite
   * @returns void
   */
  const handleFavorite = async (resume: Resume) => {
    try {
      await request({
        method: 'POST',
        url: `/api/resume/favorite/${resume._id}`,
      }).then(() => {
        resume.favorite = !resume.favorite;
      });
      // Optionally, you can update the state to reflect the change in the UI
    } catch (error) {
      console.error('Error marking resume as favorite:', error);
    }
  };

  /**
   * Delete a resume.
   *
   * @param {string}
   * @returns void
   */
  const handleDelete = async (resumeId: string) => {
    try {
      await request({
        method: 'DELETE',
        url: `/api/resume/${resumeId}`,
      }).then(() => {
        // Remove the resume from the history array
        const updatedHistory = history.filter((resume) => resume._id !== resumeId);
        // Update the state to reflect the change in the UI
        setHistory(updatedHistory);
      });
    } catch (error) {
      console.error('Error deleting resume:', error);
    }
  };

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div className="min-h-screen">
      <main>
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          <div className="px-4 py-6 sm:px-0">
            <h2 className="text-2xl font-semibold">Welcome, {user.firstName}!</h2>
            <p className="mt-1 text-sm text-gray-600">Here are your previously generated resumes</p>

            <div className="mt-6">
              <h3 className="text-lg font-medium">Past Resumes</h3>
              {history.length > 0 ? (
                <div className="overflow-x-auto">
                  <table className="min-w-full divide-y divide-gray-200 mt-3">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
                        <th scope="col" className="pl-0 pr-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Title
                        </th>
                        <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Created At
                        </th>
                        <th scope="col" className="relative px-6 py-3">
                          <span className="sr-only">View</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {currentResumes.map((resume) => (
                        <tr key={resume._id}>
                          <td className="whitespace-nowrap text-center text-sm font-medium">
                            <button onClick={() => handleFavorite(resume)} className="text-yellow-500 hover:text-yellow-700">
                              <Star size={16} strokeWidth={2} color={resume.favorite ? '#FFD700' : 'currentColor'} fill={resume.favorite ? '#FFD700' : 'none'} />
                            </button>
                          </td>

                          <td className="pl-0 pr-6 py-4 whitespace-nowrap text-sm font-medium">
                            <Link to={`/dashboard/builder/${resume._id}`} className="text-gray-900 hover:text-indigo-900">
                              {resume.title}
                            </Link>
                          </td>

                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                            <FormattedDateComponent timestamp={resume.timestamp} includeTime={true} />
                          </td>
                          
                          <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium w-auto">
                            <div className="align-middle inline-flex text-right space-x-2">
                              <button onClick={() => handleDelete(resume._id)} className="text-red-600 hover:text-red-900">
                                <Trash2 size={16} strokeWidth={2} />
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="flex justify-between mt-4">
                    <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} className="px-4 py-2 bg-gray-300 text-gray-700 rounded disabled:opacity-50">
                      Previous
                    </button>
                    <span className="px-4 py-2">
                      Page {currentPage} of {totalPages}
                    </span>
                    <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} className="px-4 py-2 bg-gray-300 text-gray-700 rounded disabled:opacity-50">
                      Next
                    </button>
                  </div>
                </div>
              ) : (
                <p className="mt-3 text-sm text-gray-600">
                  No resumes. But you can
                  <Link to={`/dashboard/builder`} className="ps-1 font-medium text-indigo-600 hover:text-indigo-500">
                    Create a new one!
                  </Link>
                </p>
              )}
            </div>

            <div className="mt-8">
              <CustomLink rainbowFlow={true} to="/dashboard/builder">
                Create a new Resume
              </CustomLink>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Dashboard;
