import { Document, Page, Text, View } from '@react-pdf/renderer';
import { LLMResponse } from '../../../../schema/llm-response.interface';
import { PersonalInfo } from '../../../../schema/personal-info.interface';
import styles from './SharedStyles';

const MissingInformation = ({ personalInfo, response }: { personalInfo?: PersonalInfo; response?: LLMResponse }) => {
  return (
    <Document title="Resume missing information" subject="Resume">
      <Page size="A4" style={styles.page}>
        <View style={styles.content}>
          <Text style={styles.title}>There was a problem!</Text>
          <Text style={styles.text}>
            Missing
            {!personalInfo && !response && ' personal info and resume data!'}
            {!personalInfo && response && ' personal info!'}
            {personalInfo && !response && ' resume data!'}
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default MissingInformation;
