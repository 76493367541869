import { Link } from 'react-router-dom';
import LegalDocument from './components/LegalDocument';

const PrivacyPolicy = () => {
  return (
    <LegalDocument title="Privacy Policy">
      <h2 className="text-2xl font-bold mb-4">1. Information We Collect</h2>
      <p className="mb-4">We collect information you provide directly to us, such as your name, email address, and resume content when you use our service.</p>

      <h2 className="text-2xl font-bold mb-4">2. How We Use Your Information</h2>
      <p className="mb-4">We use the information we collect to provide, maintain, and improve our services, to communicate with you, and to personalize your experience.</p>

      <h2 className="text-2xl font-bold mb-4">3. Information Sharing and Disclosure</h2>
      <p className="mb-4">
        We do not sell your personal information or use it for marketing purposes without your explicit consent. We may share your information with third-party service providers who perform services
        on our behalf, subject to confidentiality agreements.
      </p>

      <h2 className="text-2xl font-bold mb-4">4. Data Security</h2>
      <p className="mb-4">We implement reasonable security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction.</p>

      <h2 className="text-2xl font-bold mb-4">5. Your Choices</h2>
      <p className="mb-4">
        You can access, update, or delete your account information at any time through your account settings. You may also contact us to request access to, correction of, or deletion of any personal
        information that you have provided to us.
      </p>

      <h2 className="text-2xl font-bold mb-4">6. Changes to This Privacy Policy</h2>
      <p className="mb-4">We may update this privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page.</p>

      <h2 className="text-2xl font-bold mb-4">7. Contact Us</h2>
      <p className="mb-4">If you have any questions about this Privacy Policy, please contact us at <Link to="mailto:privacy@resumerescue.ai">privacy@resumerescue.ai</Link>.</p>
    </LegalDocument>
  );
};

export default PrivacyPolicy;
