import { Button } from '@/components/ui/button';
import { useSidebar } from '@context/SidebarContext';
import { Adsense } from '@ctrl/react-adsense';
import { getGoogleAdSenseAdStatus } from '@pages/components/GoogleAdSenseAdTest';
import { Education, Experience, LLMResponse } from '@schema/llm-response.interface';
import { Brain, BrainCog, GraduationCap, ScrollText } from 'lucide-react';
import { useEffect, useState } from 'react';
import ModifyCertificationsComponent from './components/ModifyCertificationsComponent';
import ModifyEducationComponent from './components/ModifyEducationComponent';
import ModifyExperienceComponent from './components/ModifyExperienceComponent';
import ModifySkillsComponent from './components/ModifySkillsComponent';

interface ResponseEditorProps {
  response: LLMResponse;
  onSetResponse: (response: LLMResponse) => void;
}

const ResponseEditor = ({ response, onSetResponse }: ResponseEditorProps) => {
  const { setVisible } = useSidebar();
  const [isSkillsEditorOpen, setIsSkillsEditorOpen] = useState(false);
  const [isEducationEditorOpen, setIsEducationEditorOpen] = useState(false);
  const [isExperienceEditorOpen, setIsExperienceEditorOpen] = useState(false);
  const [isCertificationsEditorOpen, setIsCertificationsEditorOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(window.innerWidth < 1024);
    const handleResize = () => setIsMobile(window.innerWidth < 1024);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isMobile]);

  const getButtonVariant = () => {
    if (isMobile) {
      return 'ghost';
    }

    return 'default';
  };

  /**
   * Set the sidebar to visible when the component mounts.
   */
  useEffect(() => {
    setVisible(true);

    // Hide the sidebar when the component unmounts.
    return () => setVisible(false);
  });

  /**
   * The buttons to display in the sidebar.
   */
  const buttons = [
    <Button key="skills" variant={getButtonVariant()} onClick={() => setIsSkillsEditorOpen(true)} className="w-full justify-start max-sm:justify-center border-r border-gray-200">
      <BrainCog className="mr-2 h-4 w-4" />
      <span className="max-sm:hidden">Edit&nbsp;</span>
      Skills
    </Button>,
    <Button key="experience" variant={getButtonVariant()} onClick={() => setIsExperienceEditorOpen(true)} className="w-full justify-start max-sm:justify-center border-r border-gray-200">
      <Brain className="mr-2 h-4 w-4" />
      <span className="max-sm:hidden">Edit&nbsp;</span>
      <span className="max-sm:hidden">Experience</span>
      <span className="lg:hidden sm:block">Exp</span>
    </Button>,
    <Button key="education" variant={getButtonVariant()} onClick={() => setIsEducationEditorOpen(true)} className="w-full justify-start max-sm:justify-center border-r border-gray-200">
      <GraduationCap className="mr-2 h-4 w-4" />
      <span className="max-sm:hidden">Edit&nbsp;</span>
      <span className="max-sm:hidden">Education</span>
      <span className="lg:hidden sm:block">Edu</span>
    </Button>,
    <Button key="certifications" variant={getButtonVariant()} onClick={() => setIsCertificationsEditorOpen(true)} className="w-full justify-start max-sm:justify-center">
      <ScrollText className="mr-2 h-4 w-4" />
      <span className="max-sm:hidden">Edit&nbsp;</span>
      <span className="max-sm:hidden">Certifications</span>
      <span className="lg:hidden sm:block">Certs</span>
    </Button>,
  ];

  /**
   * Updates the skills in the response.
   *
   * @param {string[]} skills The new skills.
   * @returns {void}
   */
  const handleSkillsChanged = (skills: string[]) => {
    onSetResponse({ ...response, skills });
    setIsSkillsEditorOpen(false);
  };

  /**
   * Updates the education in the response.
   *
   * @param {Education[]} education The new education.
   * @returns {void}
   */
  const handleEducationChange = (education: Education[]) => {
    onSetResponse({ ...response, education });
    setIsEducationEditorOpen(false);
  };

  /**
   * Updates the experience in the response.
   *
   * @param {Experience[]} experiences The new experiences.
   * @returns {void}
   */
  const handleExperienceChange = (experiences: Experience[]) => {
    onSetResponse({ ...response, experiences });
    setIsExperienceEditorOpen(false);
  };

  /**
   * Updates the certifications in the response.
   *
   * @param {string[]} certifications The new certifications.
   * @returns {void}
   */
  const handleCertificationsChange = (certifications: string[]) => {
    onSetResponse({ ...response, certifications });
    setIsCertificationsEditorOpen(false);
  };

  return (
    <>
      <div className="lg:w-64 lg:flex-shrink-0">
        {/* Desktop sidebar */}
        <div className="hidden lg:flex lg:flex-col lg:w-64 lg:fixed lg:inset-y-0 lg:border-r lg:border-gray-200 lg:pb-4 lg:bg-white/70">
          <div className="flex-1 flex flex-col overflow-y-auto">
            <nav className="px-3 mt-3">
              <div className="space-y-1">{buttons.map((button) => button)}</div>
            </nav>

            <Adsense client="ca-pub-3660131154282325" slot="6266681672" adTest={getGoogleAdSenseAdStatus()} layout="in-article" format="fluid" />
          </div>
        </div>

        {/* Mobile bottom bar */}
        <div className="lg:hidden fixed bottom-0 left-0 right-0 z-40 bg-white border-t border-gray-200 flex justify-around items-center h-10">{buttons.map((button) => button)}</div>
      </div>

      <ModifySkillsComponent isVisible={isSkillsEditorOpen} setIsVisible={setIsSkillsEditorOpen} skills={response.skills} onSet={handleSkillsChanged} />
      <ModifyEducationComponent isVisible={isEducationEditorOpen} setIsVisible={setIsEducationEditorOpen} items={response.education} onSet={handleEducationChange} />
      <ModifyExperienceComponent isVisible={isExperienceEditorOpen} setIsVisible={setIsExperienceEditorOpen} items={response.experiences} onSet={handleExperienceChange} />
      <ModifyCertificationsComponent isVisible={isCertificationsEditorOpen} setIsVisible={() => setIsCertificationsEditorOpen} items={response.certifications} onSet={handleCertificationsChange} />
    </>
  );
};

export default ResponseEditor;
