import { createContext, useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface ErrorContextSchema {
  globalError: string | null;
  setError: (error: string) => void;
  clearError: () => void;
}

const ErrorContext = createContext<ErrorContextSchema>({
  globalError: null,
  setError: () => void 0,
  clearError: () => void 0,
});

export const ErrorProvider = ({ children }: { children: React.ReactNode }) => {
  const [globalError, setGlobalError] = useState<string | null>(null);
  const location = useLocation();

  const setError = (error: string) => {
    setGlobalError(error);
    // Automatically clear the error after 5 seconds
    setTimeout(() => setGlobalError(null), 5000);
  };

  const clearError = () => {
    setGlobalError(null);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const error = params.get('error');
    if (error) {
      setError(error);
    }
  }, [location]);

  return <ErrorContext.Provider value={{ globalError, setError, clearError }}>{children}</ErrorContext.Provider>;
};

export const useError = () => useContext(ErrorContext);
