import React from 'react';

interface RainbowGlowStyles {
  container: React.CSSProperties;
  glow: React.CSSProperties;
  inner: React.CSSProperties;
}

export const useRainbowGlow = (duration = 3, blur = 5, spread = 4, opacity = 0.75, hoverOpacity = 1, hoverBlur = 10): RainbowGlowStyles => {
  const containerStyle: React.CSSProperties = {
    position: 'relative',
    overflow: 'hidden',
  };

  const glowStyle: React.CSSProperties = {
    content: '""',
    position: 'absolute',
    inset: `-${spread}px`,
    background: 'linear-gradient(45deg, #ff0000 0%, #ff9a00 10%, #d0de21 20%, #4fdc4a 30%, #3fdad8 40%, #2fc9e2 50%, #1c7fee 60%, #5f15f2 70%, #ba0cf8 80%, #fb07d9 90%, #ff0000 100%)',
    backgroundSize: '200% 200%',
    animation: `rainbow-flow ${duration}s linear infinite`,
    opacity: opacity,
    filter: `blur(${blur}px)`,
    transition: 'opacity 0.3s ease, filter 0.3s ease',
  };

  const innerStyle: React.CSSProperties = {
    position: 'relative',
    zIndex: 1,
    backgroundColor: 'inherit',
  };

  return {
    container: containerStyle,
    glow: glowStyle,
    inner: innerStyle,
  };
};
