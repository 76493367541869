import { Button } from '@/components/ui/button';
import { useLoading } from '@context/LoadingContext';
import axios from 'axios';
import { useState } from 'react';

const ContactForm = () => {
  const { isLoading, setIsLoading } = useLoading();
  const [formData, setFormData] = useState({
    name: '',
    subject: '',
    phone: '',
    email: '',
    message: '',
  });
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsLoading(true);
    const res = await axios
      .post('/api/contact', {
        ...formData,
      })
      .finally(() => setIsLoading(false));

    if (res.status !== 201) {
      setIsError(true);
      return;
    }

    setIsSuccess(true);
    setFormData({ name: '', subject: '', phone: '', email: '', message: '' });
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div className="grid md:grid-cols-2 gap-4">
        <input type="text" name="name" value={formData.name} onChange={handleChange} className="w-full px-4 py-2 border rounded-lg" placeholder="Full Name" required />
        <input type="text" name="subject" value={formData.subject} onChange={handleChange} className="w-full px-4 py-2 border rounded-lg" placeholder="Subject" required />
      </div>
      <div className="grid md:grid-cols-2 gap-4">
        <input type="tel" name="phone" value={formData.phone} onChange={handleChange} className="w-full px-4 py-2 border rounded-lg" placeholder="Phone Number" />
        <input type="email" name="email" value={formData.email} onChange={handleChange} className="w-full px-4 py-2 border rounded-lg" placeholder="Email" required />
      </div>
      <textarea name="message" value={formData.message} onChange={handleChange} className="w-full px-4 py-2 border rounded-lg" placeholder="Your message" rows={5} required></textarea>
      <Button type="submit" variant={'rainbowGlow'} disabled={isLoading}>
        Submit
      </Button>
      {isSuccess && (
        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded" role="alert">
          <p>Your message has been sent successfully!</p>
        </div>
      )}
      {isError && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded" role="alert">
          <p>There was an error sending your message. Please try again.</p>
        </div>
      )}
    </form>
  );
};

const ContactPage = () => {
  return (
    <div className="container mx-auto px-4 py-16">
      <h1 className="text-4xl font-bold text-center mb-16">Have a question?</h1>
      <ContactForm />
    </div>
  );
};

export default ContactPage;
