import { Navigate, Route, Routes } from 'react-router-dom';
import AboutPage from './About';
import PublicFooter from './components/PublicFooter';
import PublicHeader from './components/PublicHeader';
import ContactPage from './Contact';
import FAQPage from './FAQ';
import LandingPage from './LandingPage';
import Login from './Login';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfUse from './TermsOfUse';

const PublicRoutes = () => {
  return (
    <>
      <PublicHeader />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/faq" element={<FAQPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/termsofuse" element={<TermsOfUse />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <PublicFooter />
    </>
  );
};

export default PublicRoutes;
