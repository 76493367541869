import { AuthProvider } from '@context/AuthContext';
import { ErrorProvider } from '@context/ErrorContext';
import { LoadingProvider } from '@context/LoadingContext';
import { MobileProvider } from '@context/MobileContext';
import AdminRoutes from '@pages/admin/AdminRoutes';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import GlobalErrorDisplay from './components/GlobalErrorDisplay';
import GoogleAnalytics from './components/GoogleAnalytics';
import LoadingSpinner from './components/LoadingSpinner';
import PrivateRoutes from './pages/dashboard/PrivateRoutes';
import PublicRoutes from './pages/PublicRoutes';

function App() {
  return (
    <>
      <div id="content">
        <MobileProvider>
          <LoadingProvider>
            <AuthProvider>
              <GoogleAnalytics trackingId={process.env.REACT_APP_GA_TRACKING_ID}>
                <Router>
                  <ErrorProvider>
                    <Routes>
                      <Route path="/*" element={<PublicRoutes />} />
                      <Route path="/dashboard/*" element={<PrivateRoutes />} />
                      <Route path="/admin/*" element={<AdminRoutes />} />
                    </Routes>
                    <LoadingSpinner />
                    <GlobalErrorDisplay />
                  </ErrorProvider>
                </Router>
              </GoogleAnalytics>
            </AuthProvider>
          </LoadingProvider>
        </MobileProvider>
      </div>
    </>
  );
}

export default App;
