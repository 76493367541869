import { Button } from '@/components/ui/button';
import { Spinner } from '@/components/ui/spinner';
import React, { useEffect, useState } from 'react';

interface ModalComponentProps {
  /**
   * The title of the modal.
   */
  title: string;

  /**
   * The subtitle of the modal.
   */
  subtitle?: string;

  /**
   * The state to control the visibility of the modal.
   */
  isModalOpen: boolean;

  /**
   * The function to set the visibility of the modal.
   */
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;

  /**
   * Whether the modal has a submit button. Defaults to true.
   * If false, the 'cancel' button text will be changed to 'Close'.
   */
  hasSubmit?: boolean;

  /**
   * The function to call when the form is submitted.
   */
  onSubmit?: () => void;

  /**
   * The loading state of the modal. If true, the submit button will show a spinner.
   */
  loading?: boolean;

  /**
   * The icon to display at the top of the modal.
   */
  icon?: React.ReactNode;

  /**
   * The background color of the icon. Defaults to 'bg-blue-100'.
   */
  iconBackgroundColor?: string;

  /**
   * The color of the icon. Defaults to 'text-blue-600'.
   */
  iconColor?: string;

  /**
   * The text to display on the submit button. Defaults to 'Submit'.
   */
  submitBtnText?: string;

  /**
   * Extra buttons to display in the footer of the modal.
   */
  extraButtons?: React.ReactNode;

  /**
   * The children of the modal.
   */
  children?: React.ReactNode;
}

const ModalComponent: React.FC<ModalComponentProps> = ({
  title,
  subtitle,
  isModalOpen,
  setIsModalOpen,
  hasSubmit = true,
  onSubmit,
  loading = false,
  icon,
  iconBackgroundColor = 'bg-blue-100',
  iconColor = 'text-blue-600',
  submitBtnText = 'Submit',
  extraButtons,
  children,
}) => {
  // State to handle the closing animation of the modal.
  const [isClosing, setIsClosing] = useState(false);

  /**
   * Closes the modal by setting the isModalOpen state to false and animating the backdrop.
   */
  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      setIsModalOpen(false);
    }, 200);
  };

  /**
   * Reset the closing state when the modal is opened.
   */
  useEffect(() => {
    if (isModalOpen) {
      setIsClosing(false);
    }
  }, [isModalOpen]);

  return (
    <>
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className={`fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity ${isClosing ? 'animate-backdrop-leave' : 'animate-backdrop-enter'}`} aria-hidden="true"></div>
          <div className="fixed inset-0 z-10 w-screen flex items-center justify-center">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
              <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 lg:min-w-[800px] flex-grow flex-shrink z-20 w-full max-w-md sm:max-w-lg max-h-screen sm:max-h-[80vh]">
                <div className="bg-white px-4 pb-4 pt-5">
                  <div className="flex items-start">
                    {icon && <div className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10 ${iconBackgroundColor} ${iconColor}`}>{icon}</div>}
                    <div className="ml-4 mt-0 text-left">
                      <h3 className="text-base font-semibold leading-6 text-gray-900" id="modal-title">
                        {title}
                      </h3>
                      {subtitle && <p className="mt-2 text-sm text-gray-500">{subtitle}</p>}
                    </div>
                  </div>
                </div>
                <div className="bg-white px-4 pb-4 pt-5 flex-grow overflow-y-auto max-h-[80vh]">{children}</div>
                <div className="bg-gray-50 px-4 py-3 flex sm:px-6 gap-4 justify-end">
                  {extraButtons}
                  {hasSubmit && (
                    <Button type="button" variant={'rainbowGlow'} onClick={onSubmit}>
                      {loading ? <Spinner className="mr-2 h-4 w-4" /> : submitBtnText}
                    </Button>
                  )}
                  <Button type="button" variant="ghost" onClick={handleClose}>
                    {hasSubmit ? 'Cancel' : 'Close'}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalComponent;
