import { Toaster } from '@/components/ui/toaster';
import { useAuth } from '@context/AuthContext';
import { SidebarProvider } from '@context/SidebarContext';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import './AdminRoutes.css';
import AdminHeader from './components/AdminHeader';
import Dashboard from './Dashboard';

const AdminRoutes = () => {
  const { user, loading } = useAuth();
  const location = useLocation();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return (
    <div id="admin">
      <SidebarProvider>
        <AdminHeader />

        <Routes>
          <Route index element={<Dashboard />} />
          <Route path="*" element={<Navigate to="/admin" replace />} />
        </Routes>
      </SidebarProvider>

      <Toaster />
    </div>
  );
};

export default AdminRoutes;
