import { Button } from '@/components/ui/button';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { ChevronRight, Clock, Coffee, Ghost, ListCheck, Send, Stars } from 'lucide-react';
import { Link } from 'react-router-dom';
import HowItWorksSection from './components/HowItWorks';

const LandingPage = () => {
  return (
    <>
      <div className="bg-black text-white min-h-screen lg:min-h-[70vh]">
        <main className="relative z-20 flex flex-col items-center justify-center text-center px-4 py-12 sm:py-20">
          <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold mb-4">
            Resumes, cover letters, icebreakers.
            <br className="hidden sm:block" />
            tailored to you, for you.
          </h1>
          <p className="text-lg sm:text-xl mb-8 max-w-2xl">Resumes that fit the job, time that fits your life.</p>
          <div className="flex flex-col sm:flex-row items-center sm:items-start justify-center space-y-4 sm:space-y-0 sm:space-x-4">
            <div>
              <Link to="/login">
                <Button variant={'rainbowGlow'} size={'lg'} className="w-full sm:w-auto">
                  Get Started <ChevronRight className="inline ml-2" size={20} />
                </Button>
              </Link>
              <p className="mt-4 text-sm">It&apos;s free!</p>
            </div>
            <div>
              <a href="#plans">
                <Button size={'lg'} className="w-full sm:w-auto text-white border border-white hover:bg-gray-900 bg-transparent">
                  Give Your Support! <ChevronRight className="inline ml-2" size={20} />
                </Button>
              </a>
            </div>
          </div>
        </main>
      </div>
      <div className="min-h-screen bg-gray-100">
        <div className="container mx-auto px-4 py-12">
          <section className="flex flex-col justify-center text-center min-h-[50vh]">
            <div className="flex items-center justify-center space-x-2 mb-4">
              <div className="bg-blue-500 text-white px-4 py-1 rounded-full flex items-center">
                <ListCheck className="h-4 w-4 mr-1" />
                <span>Curriculum Vitae</span>
              </div>
            </div>
            <h2 className="text-2xl sm:text-3xl font-bold mb-4">The course of (one&apos;s) life</h2>
            <Popover>
              <p className="text-lg sm:text-xl text-gray-600 mb-8">
                Present your experience that matters for the job you want{' '}
                <PopoverTrigger triggerOnHover={true} className="font-bold text-blue-600 hover:text-blue-400">
                  automagically
                  <Stars className="inline ml-1" size={20} />
                </PopoverTrigger>
              </p>
              <PopoverContent side="top">
                Using advanced prompting techniques only the experience that applies directly to the job you are applying to is used without any extra work from you.
              </PopoverContent>
            </Popover>

            <div className="flex flex-col sm:flex-row items-center justify-center space-y-4 sm:space-y-0 sm:space-x-4">
              <Link to="/login">
                <Button variant={'rainbowGlow'} size={'lg'} className="w-full sm:w-auto">
                  Get started free <ChevronRight className="inline ml-2" size={20} />
                </Button>
              </Link>
              <Link to="/faq">
                <Button variant={'outline'} size={'lg'} className="w-full sm:w-auto border border-white hover:bg-gray-900 hover:text-white">
                  Learn more <ChevronRight className="inline ml-2" size={20} />
                </Button>
              </Link>
            </div>
          </section>

          <HowItWorksSection />

          <section className="flex flex-col justify-center text-center min-h-[50vh]">
            <div className="flex items-center justify-center space-x-2 mb-4">
              <div className="bg-orange-500 text-white px-4 py-1 rounded-full flex items-center">
                <Clock className="h-4 w-4 mr-1" />
                <span>Saving you time</span>
              </div>
            </div>
            <h2 className="text-2xl sm:text-3xl font-bold mb-4">Your Job Application Assistant</h2>
            <p className="text-lg sm:text-xl text-gray-600 mb-8">
              We save you time and effort by selecting the best job-specific information from your life resume to create tailored resumes, cover letters, and icebreaker messages for any job posting.
            </p>
            <div className="flex flex-col sm:flex-row items-center justify-center space-y-4 sm:space-y-0 sm:space-x-4">
              <Link to="/login">
                <Button variant={'rainbowGlow'} size={'lg'} className="w-full sm:w-auto">
                  Get started free <ChevronRight className="inline ml-2" size={20} />
                </Button>
              </Link>
              <Link to="/faq">
                <Button variant={'outline'} size={'lg'} className="w-full sm:w-auto border border-white hover:bg-gray-900 hover:text-white">
                  Learn more <ChevronRight className="inline ml-2" size={20} />
                </Button>
              </Link>
            </div>
          </section>

          <section className="flex flex-col justify-center text-center mt-12 sm:mt-20">
            <h2 className="text-2xl sm:text-3xl font-bold mb-4">Does this sound familiar?</h2>
            <div className="grid sm:grid-cols-2 gap-6 sm:gap-8 mb-12">
              <div className="bg-white rounded-lg shadow-md p-6">
                <h3 className="text-lg sm:text-xl font-bold mb-4 flex items-center justify-center sm:justify-start">
                  <Coffee className="mr-2 text-blue-600" /> The Daily Grind
                </h3>
                <p>
                  We&apos;ve been there: 37 tabs open, 14 half-finished applications, and enough coffee to fuel a small country. Our app is designed to give you back those precious hours (and save you
                  from a potential caffeine overdose).
                </p>
              </div>
              <div className="bg-white rounded-lg shadow-md p-6">
                <h3 className="text-lg sm:text-xl font-bold mb-4 flex items-center justify-center sm:justify-start">
                  <Send className="mr-2 text-blue-600" /> The &quot;Send and Pray&quot; Method
                </h3>
                <p>
                  Remember the good old days of sending the same generic resume to 100 different jobs and hoping for the best? Yeah, we don&apos;t miss that either. Our AI tailors each application, so
                  you can say goodbye to the spray-and-pray approach.
                </p>
              </div>
              <div className="bg-white rounded-lg shadow-md p-6">
                <h3 className="text-lg sm:text-xl font-bold mb-4 flex items-center justify-center sm:justify-start">
                  <Ghost className="mr-2 text-blue-600" /> The Ghosting Epidemic
                </h3>
                <p>
                  We can&apos;t promise you won&apos;t get ghosted (we&apos;re not miracle workers), but with our perfectly crafted applications, you&apos;ll at least know it&apos;s not because of
                  your resume. It&apos;s probably because the hiring manager&apos;s cat walked across their keyboard and deleted your email. Probably.
                </p>
              </div>
              <div className="bg-white rounded-lg shadow-md p-6">
                <h3 className="text-lg sm:text-xl font-bold mb-4 flex items-center justify-center sm:justify-start">
                  <Clock className="mr-2 text-blue-600" /> Time is Money (and Sanity)
                </h3>
                <p>
                  Life&apos;s too short to spend it all on job applications. With ResumeAI, you can create tailored applications in minutes, leaving you more time for important things, like
                  binge-watching your favorite shows or perfecting your sourdough starter.
                </p>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default LandingPage;
