import axios from 'axios';
import { useCallback } from 'react';

export const useLinkedinAuth = () => {
  const initiateLinkedinAuth = useCallback(async () => {
    try {
      const response = await axios.get<{ authUrl: string }>('/api/auth/linkedin/auth');
      window.location.href = response.data.authUrl;
    } catch (error: unknown) {
      console.error('Error initiating LinkedIn Auth:', error);
    }
  }, []);

  return { initiateLinkedinAuth };
};
