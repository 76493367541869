import React from 'react';

/**
 * Format the date to a human-readable format
 *
 * @param {Date | string} date - The date to format
 * @param {boolean} includeTime - Whether to include the time of day
 * @returns {string} - The formatted date
 */
const formattedDate = (date: Date | string, includeTime = false): string => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  if (includeTime) {
    options.hour = 'numeric';
    options.minute = 'numeric';
    options.second = 'numeric';
  }

  return new Date(date).toLocaleDateString('en-US', options);
};

interface FormattedDateComponentProps {
  timestamp: string;
  includeTime?: boolean;
}

const FormattedDateComponent: React.FC<FormattedDateComponentProps> = ({ timestamp, includeTime = false }) => {
  return <span>{formattedDate(timestamp, includeTime)}</span>;
};

export default FormattedDateComponent;
