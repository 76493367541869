import ModalComponent from '@pages/components/ModalComponent';
import { BrainCog } from 'lucide-react';
import { useEffect, useState } from 'react';
import { WithContext as ReactTags, SEPARATORS } from 'react-tag-input';
import { Tag } from 'react-tag-input/types/components/SingleTag';

interface ModifySkillsComponentProps {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  skills: string[];
  onSet: (skills: string[]) => void;
}

const ModifySkillsComponent = ({ isVisible, setIsVisible, skills, onSet }: ModifySkillsComponentProps) => {
  const [tags, setTags] = useState<Tag[]>([]);

  /**
   * Load tags from the skills prop when the component mounts
   */
  useEffect(() => {
    setTags(skills.map((skill, index) => ({ id: index.toString(), text: skill, className: '' })));
  }, [skills]);

  /**
   * Handle the deletion of a tag
   *
   * @param {number} index The index of the tag to delete
   * @returns {void}
   */
  const handleDelete = (index: number) => {
    setTags(tags.filter((_, i) => i !== index));
  };

  /**
   * Update a tag at a specific index
   *
   * @param {number} index The index of the tag to update
   * @param {Tag} newTag The new tag to replace the old one
   * @returns {void}
   */
  const onTagUpdate = (index: number, newTag: Tag) => {
    const updatedTags = [...tags];
    updatedTags.splice(index, 1, newTag);
    setTags(updatedTags);
  };

  /**
   * Handle the addition of a new tag
   *
   * @param {Tag} tag The tag to add
   * @returns {void}
   */
  const handleAddition = (tag: Tag) => {
    setTags((prevTags) => {
      return [...prevTags, tag];
    });
  };

  /**
   * Handle the drag and drop of a tag
   *
   * @param {Tag} tag The tag being dragged
   * @param {number} currPos The current position of the tag
   * @param {number} newPos The new position of the tag
   * @returns {void}
   */
  const handleDrag = (tag: Tag, currPos: number, newPos: number) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  /**
   * Clear all tags
   *
   * @returns {void}
   */
  const onClearAll = () => {
    setTags([]);
  };

  /**
   * Notify the parent component of the updated skills
   */
  const handleSubmit = () => {
    onSet(tags.map((tag) => tag.text));
    setIsVisible(false);
  };

  return (
    <ModalComponent
      title="Your Skills"
      subtitle="Add, edit, or remove your skills that will appear on your resume."
      isModalOpen={isVisible}
      setIsModalOpen={setIsVisible}
      onSubmit={handleSubmit}
      submitBtnText="Save Skills"
      loading={false}
      icon={<BrainCog className="h-6 w-6" />}>
      <ReactTags
        tags={tags}
        separators={[SEPARATORS.ENTER, SEPARATORS.COMMA]}
        handleDelete={handleDelete}
        handleAddition={handleAddition}
        handleDrag={handleDrag}
        onTagUpdate={onTagUpdate}
        inputFieldPosition="bottom"
        editable
        clearAll
        onClearAll={onClearAll}
        classNames={{
          tags: 'ReactTags__tags flex flex-wrap gap-2',
          tagInput: 'ReactTags__tagInput w-full',
          tagInputField:
            'ReactTags__tagInputField flex h-10 w-full rounded-md border border-gray-300 bg-white px-3 py-2 text-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500',
          selected: 'ReactTags__selected flex flex-wrap gap-2',
          tag: 'ReactTags__tag inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-blue-100 text-blue-800',
          remove: 'ReactTags__remove ml-2 cursor-pointer text-red-500 hover:text-red-700',
          suggestions: 'ReactTags__suggestions absolute z-10 mt-1 w-full bg-white border border-gray-300 rounded-md shadow-lg',
          activeSuggestion: 'ReactTags__activeSuggestion bg-blue-500 text-white',
          editTagInput: 'ReactTags__editInput w-full',
          editTagInputField:
            'ReactTags__editTagInput flex h-10 w-full rounded-md border border-gray-300 bg-white px-3 py-2 text-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500',
          clearAll: 'ReactTags__clearAll mt-2 text-red-500 cursor-pointer hover:text-red-700',
        }}
      />
    </ModalComponent>
  );
};

export default ModifySkillsComponent;
