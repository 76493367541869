import { Text } from '@react-pdf/renderer';
import React from 'react';
import styles from '../SharedStyles';

interface BoldedTextProps {
  text: string;
  boldedWords: string[];
}

const BoldedText: React.FC<BoldedTextProps> = ({ text, boldedWords }) => {
  const result: JSX.Element[] = [];
  let unboldedText = '';

  const words = text.split(' ');
  words.forEach((word, index) => {
    // Remove special characters
    const filteredWord = word.replace(/[^a-zA-Z0-9]/g, '');
    // Check if the word is bolded
    const isBold = boldedWords.includes(filteredWord.toLowerCase());

    // If the word is bolded, add the current text to the result and clear it
    if (isBold) {
      // Add the unbolded text to the result
      if (unboldedText.length) {
        result.push(
          <Text key={result.length} style={styles.text}>
            {unboldedText.trim()}
          </Text>,
        );

        // Clear the unbolded text
        unboldedText = '';
      }

      // Add the bolded text to the result
      result.push(
        <Text key={result.length} style={styles.bold}>
          {' ' + word + ' '}
        </Text>,
      );
    }
    // If the word is not bolded, add it to the existing unbolded text
    else {
      unboldedText += ' ' + word;
    }

    // If we're at the end of the text, add the last unbolded text to the result
    if (index === words.length - 1 && unboldedText.length) {
      result.push(
        <Text key={result.length} style={styles.text}>
          {unboldedText.trim()}{'\n'}
        </Text>,
      );
    }
  });

  return <>{result}</>;
};

export default BoldedText;
