import { Document, Link, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { LLMResponse } from '@schema/llm-response.interface';
import { PersonalInfo } from '@schema/personal-info.interface';
import SpaceBetween from './components/SpaceBetween';
import MapWorkExperienceComponent from './components/MapWorkExperienceComponent';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: 'white',
    padding: 20,
  },
  sidebar: {
    width: 150,
  },
  content: {
    width: '100%',
    flexGrow: 1,
    // paddingLeft: 20,
    // borderLeft: '1px solid #ccc',
  },
  heading: {
    fontSize: 24,
    marginBottom: 10,
  },
  textContent: {
    fontSize: 12,
  },
  experienceSection: {
    marginBottom: 10,
  },
  experienceTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    margin: 0,
    padding: 0,
  },
  experienceCompanyAndDateContainer: {
    margin: 0,
    padding: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    position: 'relative',
  },
  experienceCompanyName: {
    margin: 0,
    padding: 0,
    display: 'flex',
    fontSize: 12,
    fontWeight: 'bold',
    flexGrow: 1,
    width: '100%',
  },
  experienceDate: {
    margin: 0,
    padding: 0,
    display: 'flex',
    fontSize: 11,
    fontStyle: 'italic',
    flexGrow: 1,
    width: '100%',
  },
  experienceResponsabilitiesSection: {
    fontSize: 12,
    marginBottom: 5,
    paddingLeft: 10,
  },
  skillsSectionHeader: {
    marginTop: 0,
    marginBottom: 10,
    color: '#2c3e50',
    borderBottom: '1px solid #ccc',
  },
  sectionHeader: {
    marginTop: 10,
    color: '#2c3e50',
    borderBottom: '1px solid #ccc',
  },
  section: {
    marginBottom: 5,
  },
  brandIcon: {
    width: 12,
    height: 12,
  },
  link: {
    fontSize: 11,
    textDecoration: 'none',
    textDecorationStyle: 'none',
  },
  indendedList: {
    fontSize: 12,
    marginBottom: 5,
    paddingLeft: 10,
  },
});

const SkillsSidebar = ({ personalInfo, response }: { personalInfo: PersonalInfo; response?: LLMResponse }) => {
  return (
    <Document title={personalInfo.name + ' - Resume'} author={personalInfo.name} subject="Resume">
      <Page size="A4" style={styles.page}>
        <View style={styles.sidebar}>
          <Text style={styles.skillsSectionHeader}>Skills</Text>
          <Text style={styles.textContent}>{response ? response.skills.map((skill) => skill).join('\n') : 'Loading...'}</Text>
        </View>
        <View style={styles.content}>
          <Text style={styles.heading}>{personalInfo.name}</Text>
          <Text style={styles.textContent}>{personalInfo.location}</Text>
          <Text style={{ marginBottom: 5 }}>
            {personalInfo.email.length ? (
              <Link style={styles.link} src={personalInfo.email}>
                {personalInfo.email}
              </Link>
            ) : null}
            {personalInfo.email.length && personalInfo.phone.length ? <Text style={styles.link}> | </Text> : null}
            {personalInfo.phone.length ? (
              <Link style={styles.link} src={`tel:${personalInfo.phone}`}>
                {personalInfo.phone}
              </Link>
            ) : null}
          </Text>
          {personalInfo.githubUrl.length ? (
            <Text style={styles.textContent}>
              <Link style={styles.link} src={personalInfo.githubUrl}>
                {personalInfo.githubUrl}
              </Link>
            </Text>
          ) : null}
          {personalInfo.linkedinUrl.length ? (
            <Text style={styles.textContent}>
              <Link style={styles.link} src={personalInfo.linkedinUrl}>
                {personalInfo.linkedinUrl}
              </Link>
            </Text>
          ) : null}
          {response ? (
            <>
              <Text style={styles.sectionHeader}>Summary</Text>
              <Text style={styles.textContent}>{response.summary}</Text>
              <Text style={styles.sectionHeader}>Experience</Text>
              <MapWorkExperienceComponent response={response} />
              <Text style={styles.sectionHeader}>Education</Text>
              {response.education.map((edu, index) => (
                <View key={index}>
                  <SpaceBetween col1={edu.school} col2={edu.date} fontSize={13} fontWeight={900} />
                  <Text style={[styles.textContent, { fontStyle: 'italic' }]}>
                    {edu.degree}, {edu.major}
                  </Text>
                  {edu.honors ? <Text style={styles.indendedList}>{edu.honors.map((honor) => '• ' + honor).join('\n')}</Text> : null}
                </View>
              ))}
              <Text style={styles.sectionHeader}>Licenses & Certifications</Text>
              {response.certifications.map((cert, index) => (
                <Text style={styles.textContent} key={index}>
                  {cert}
                </Text>
              ))}
            </>
          ) : (
            <Text>Load a Resume from history or enter a new job posting</Text>
          )}
        </View>
      </Page>
    </Document>
  );
};

export default SkillsSidebar;
