import { useAuth } from '@context/AuthContext';
import { useSidebar } from '@context/SidebarContext';
import { ExternalLink, Menu, X } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const PrivateHeader = () => {
  const { className } = useSidebar();
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const handleLogout = async () => {
    await logout();
    navigate('/login');
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const getStripePortalLink = (customerId: string) => {
    const baseUrl = process.env.NODE_ENV !== 'production' ? 'https://billing.stripe.com/p/login/test_dR66pue1yd2objG6oo' : 'https://billing.stripe.com/p/login/eVabIR1j15EXfYI4gg';
    return `${baseUrl}?customer_id=${customerId}`;
  };

  const getStripeSubscribeLink = (customerId: string) => {
    const baseUrl = process.env.NODE_ENV !== 'production' ? 'https://buy.stripe.com/test_dR6bJ8att9bg08MbII' : 'https://buy.stripe.com/cN28wG3JkgUf4uc3cc';
    return `${baseUrl}?customer_id=${customerId}`;
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <header className={`absolute top-0 bg-white/70 backdrop-blur-md shadow-md z-50 ${className}`}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center py-6">
            <div className="flex items-center">
              <img src="/favicon-32x32.png" alt="Logo" className="h-8 w-8 mr-4" />
              <Link to="/" className="ml-2 text-xl font-bold hover:text-blue-400">
                {process.env.REACT_APP_NAME}
              </Link>
            </div>
            <nav className="hidden md:flex items-center">
              <Link to="/dashboard" className="hover:text-blue-400 px-3 py-2 rounded-md text-sm font-medium">
                Dashboard
              </Link>
              <div className="ml-3 relative" ref={dropdownRef}>
                <div>
                  <button type="button" className="bg-transparent flex text-sm h-[32px] items-center" id="user-menu" aria-expanded="false" aria-haspopup="true" onClick={toggleDropdown}>
                    <span className="sr-only">Open user menu</span>
                    <div className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                      {user?.picture ? (
                        <img className="h-8 w-8 rounded-full" src={user.picture} alt="" />
                      ) : (
                        <svg className="h-8 w-8 rounded-full" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M10 12a4 4 0 100-8 4 4 0 000 8zM2 16a8 8 0 1116 0 8 8 0 01-16 0zm10-2a2 2 0 11-4 0 2 2 0 014 0z" />
                        </svg>
                      )}
                    </div>
                    <span className="hover:text-blue-400 ps-2 text-sm font-medium">{user?.firstName ? 'Welcome, ' + user.firstName + '!' : ''}</span>
                  </button>
                </div>
                {isDropdownOpen && (
                  <div
                    className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="user-menu">
                    <Link to="/dashboard/profile" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem" onClick={() => setIsDropdownOpen(false)}>
                      Your Profile
                    </Link>
                    {user && (
                      <>
                        {user.subscriptionStatus === 'active' && (
                          <Link
                            to={getStripePortalLink(user.stripeCustomerId)}
                            target="_blank"
                            className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex justify-start"
                            role="menuitem"
                            onClick={() => setIsDropdownOpen(false)}>
                            <ExternalLink size={16} className="mr-2" />
                            Your Subscription
                          </Link>
                        )}
                        {user.subscriptionStatus !== 'active' && (
                          <Link
                            to={getStripeSubscribeLink(user.stripeCustomerId)}
                            target="_blank"
                            className="px-4 py-2 text-sm text-green-700 hover:bg-green-100 flex justify-start"
                            role="menuitem"
                            onClick={() => setIsDropdownOpen(false)}>
                            <ExternalLink size={16} className="mr-2" />
                            Subscribe Now
                          </Link>
                        )}
                      </>
                    )}
                    <button
                      onClick={() => {
                        setIsDropdownOpen(false);
                        handleLogout();
                      }}
                      className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      role="menuitem">
                      Sign out
                    </button>

                    {user?.role === 'admin' && (
                      <>
                        <hr />
                        <Link to="/admin" className="block w-full text-left px-4 py-2 text-sm text-orange-700 hover:bg-orange-100" role="menuitem">
                          Admin Dashboard
                        </Link>
                      </>
                    )}
                  </div>
                )}
              </div>
            </nav>
            <div className="md:hidden">
              <button onClick={toggleMenu} className="text-black focus:outline-none focus:ring-2 focus:ring-blue-400 rounded-md p-2">
                {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
              </button>
            </div>
          </div>
        </div>
        {/* Mobile menu */}
        {isMenuOpen && (
          <div className="md:hidden fixed inset-0 z-100 bg-white/95 backdrop-blur-md">
            <div className="flex justify-between h-16 items-center p-4">
              <Link to="/" className="text-xl font-bold hover:text-blue-400" onClick={toggleMenu}>
                {process.env.REACT_APP_NAME}
              </Link>
              <button onClick={toggleMenu} className="text-black focus:outline-none focus:ring-2 focus:ring-blue-400 rounded-md p-2">
                <X size={24} />
              </button>
            </div>
            <div className="bg-white/95 backdrop-blur-md">
              <nav className="px-4 pt-4 pb-8">
                <div className="space-y-4">
                  <Link to="/dashboard" className="hover:text-blue-400 block px-3 py-2 rounded-md text-lg font-medium" onClick={toggleMenu}>
                    Dashboard
                  </Link>
                  <Link to="/dashboard/profile" className="hover:text-blue-400 block px-3 py-2 rounded-md text-lg font-medium" onClick={toggleMenu}>
                    Your Profile
                  </Link>
                  {user && (
                    <>
                      {user.subscriptionStatus === 'active' && (
                        <Link to={getStripePortalLink(user.stripeCustomerId)} target="_blank" className="hover:text-blue-400 block px-3 py-2 rounded-md text-lg font-medium" onClick={toggleMenu}>
                          Your Subscription
                        </Link>
                      )}
                      {user.subscriptionStatus !== 'active' && (
                        <Link to={getStripeSubscribeLink(user.stripeCustomerId)} target="_blank" className="hover:text-blue-400 block px-3 py-2 rounded-md text-lg font-medium" onClick={toggleMenu}>
                          Subscribe Now
                        </Link>
                      )}
                    </>
                  )}
                  <button
                    onClick={() => {
                      toggleMenu();
                      handleLogout();
                    }}
                    className="hover:text-blue-400 block w-full text-left px-3 py-2 rounded-md text-lg font-medium">
                    Sign out
                  </button>
                  {user?.role === 'admin' && (
                    <>
                      <hr />
                      <Link to="/admin" className="hover:text-blue-400 block w-full text-left px-3 py-2 rounded-md text-lg font-medium" onClick={toggleMenu}>
                        Admin Dashboard
                      </Link>
                    </>
                  )}
                </div>
              </nav>
            </div>
          </div>
        )}
      </header>
    </>
  );
};

export default PrivateHeader;
