import { Button } from '@/components/ui/button';
import ModalComponent from '@pages/components/ModalComponent';
import { LLMResponse } from '@schema/llm-response.interface';
import { Check, Copy, Flame } from 'lucide-react';
import { useEffect, useState } from 'react';

interface IceBreakerComponentProps {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  response: LLMResponse;
}

const IceBreakerComponent = ({ isVisible, setIsVisible, response }: IceBreakerComponentProps) => {
  const [isCopied, setIsCopied] = useState(false);

  /**
   * Copy the cover letter to the clipboard.
   */
  const copyIceBreaker = () => {
    navigator.clipboard.writeText(response.icebreakerMessage);
    setIsCopied(true);
  };

  /**
   * Clear the 'copied' state after 3 seconds.
   */
  useEffect(() => {
    if (isCopied) {
      const timeout = setTimeout(() => {
        setIsCopied(false);
      }, 3000);

      return () => clearTimeout(timeout);
    }
  }, [isCopied]);

  return (
    <ModalComponent
      title="Ice Breaker"
      subtitle="Include this icebreaker message to get the conversation rolling!"
      isModalOpen={isVisible}
      setIsModalOpen={setIsVisible}
      hasSubmit={false}
      loading={false}
      icon={<Flame className="h-6 w-6" />}
      extraButtons={
        <Button variant="rainbowGlow" onClick={copyIceBreaker}>
          {isCopied && <Check className="h-6 w-6" />}
          {!isCopied && <Copy className="h-6 w-6" />}
        </Button>
      }>
      <pre className="text-wrap">{response.icebreakerMessage}</pre>
    </ModalComponent>
  );
};

export default IceBreakerComponent;
