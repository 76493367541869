import * as PopoverPrimitive from '@radix-ui/react-popover';
import * as React from 'react';

import { cn } from '@/lib/utils';

const PopoverContext = React.createContext<{ isOpen: boolean; setIsOpen: React.Dispatch<React.SetStateAction<boolean>> } | null>(null);

const Popover = React.forwardRef<React.ElementRef<typeof PopoverPrimitive.Root>, React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Root>>(({ children, ...props }, ref) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <PopoverContext.Provider value={{ isOpen, setIsOpen }}>
      <PopoverPrimitive.Root open={isOpen} onOpenChange={setIsOpen} {...props}>
        {children}
      </PopoverPrimitive.Root>
    </PopoverContext.Provider>
  );
});
Popover.displayName = PopoverPrimitive.Root.displayName;

const PopoverTrigger = React.forwardRef<React.ElementRef<typeof PopoverPrimitive.Trigger>, React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Trigger> & { triggerOnHover?: boolean }>(
  ({ children, triggerOnHover = false, ...props }, ref) => {
    const context = React.useContext(PopoverContext);

    if (!context) {
      throw new Error('PopoverTrigger must be used within a Popover');
    }

    const { setIsOpen } = context;

    const handleMouseEnter = () => {
      if (triggerOnHover) {
        setIsOpen(true);
      }
    };

    const handleMouseLeave = () => {
      if (triggerOnHover) {
        setIsOpen(false);
      }
    };

    return (
      <PopoverPrimitive.Trigger ref={ref} {...props} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {children}
      </PopoverPrimitive.Trigger>
    );
  },
);
PopoverTrigger.displayName = PopoverPrimitive.Trigger.displayName;

const PopoverContent = React.forwardRef<React.ElementRef<typeof PopoverPrimitive.Content>, React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content>>(
  ({ className, align = 'center', sideOffset = 4, ...props }, ref) => (
    <PopoverPrimitive.Portal>
      <PopoverPrimitive.Content
        ref={ref}
        align={align}
        sideOffset={sideOffset}
        className={cn(
          'z-50 w-72 rounded-md border bg-popover p-4 text-popover-foreground shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
          className,
        )}
        {...props}
      />
    </PopoverPrimitive.Portal>
  ),
);
PopoverContent.displayName = PopoverPrimitive.Content.displayName;

export { Popover, PopoverContent, PopoverTrigger };
