import { Button } from '@/components/ui/button';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { useLoading } from '@context/LoadingContext';
import useApi from '@hooks/useApi';
import axios from 'axios';
import braintree from 'braintree';
import React, { useEffect, useState } from 'react';

interface CurrentSubscriptionProps {
  onModifySubscription: () => void;
  onSetSubscription: (subscription: braintree.Subscription) => void;
  onSetPlans: (plans: braintree.Plan[]) => void;
}

const CurrentSubscription: React.FC<CurrentSubscriptionProps> = ({ onSetSubscription, onSetPlans, onModifySubscription }: CurrentSubscriptionProps) => {
  const [subscription, setSubscription] = useState<braintree.Subscription | undefined>(undefined);
  const [plans, setPlans] = useState<braintree.Plan[]>([]);
  const { loading, request } = useApi();
  const { setIsLoading } = useLoading();

  // Fetch all available plans and the current subscription plan subscribed by the user
  useEffect(() => {
    // Get all available plans
    const fetchPlans = async () => {
      const response = await axios.get<braintree.Plan[]>('/api/payments/plans');
      setPlans(response.data);
    };

    // Fetch the current subscription plan
    const fetchCurrentSubscription = async () => {
      const response: braintree.Subscription = await request({ url: '/api/payments/subscription' });
      setSubscription(response);
    };

    // Set the loading state to true
    setIsLoading(true);

    // Fetch all available plans
    fetchPlans()
      // and the current subscription plan
      .then(() => fetchCurrentSubscription())
      // Set the loading state to false
      .finally(() => setIsLoading(false));
  }, [request, setIsLoading]);

  // Set the current subscription in the parent component
  useEffect(() => {
    if (subscription) onSetSubscription(subscription);
  }, [subscription, onSetSubscription]);

  // Set all available plans in the parent component
  useEffect(() => {
    if (plans) onSetPlans(plans);
  }, [plans, onSetPlans]);

  // Display a loading indicator if the data is still being fetched
  useEffect(() => {
    setIsLoading(loading);
  }, [loading, setIsLoading]);

  /**
   * Get the plan name using the plan ID
   *
   * @param {string} planId - The plan ID
   * @returns {string} - The plan name
   */
  const getPlanNameUsingPlanId = (planId: string): string => {
    return plans.find((p) => p.id === planId)?.name || 'Unknown Plan';
  };

  /**
   * Format the date to a human-readable format
   *
   * @param {Date | string} date - The date to format
   * @returns {string} - The formatted date
   */
  const formattedDate = (date: Date | string): string => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>
          <div className="px-4 sm:px-0">
            <h3 className="text-base font-semibold leading-7 text-gray-900">Subscription Information</h3>

            {subscription && subscription.nextBillingDate ? (
              <p className="mt-1 max-w-2xl text-sm font-normal leading-6 text-gray-500">
                Next payment ${subscription.nextBillAmount} on {formattedDate(subscription.nextBillingDate)}.
              </p>
            ) : null}
          </div>
        </CardTitle>
      </CardHeader>
      {(!subscription || !plans.length) && loading ? (
        <CardContent>
          <p>Hang on...</p>
        </CardContent>
      ) : null}
      {!subscription ? (
        <CardContent>
          <p>You are not subscribed to a plan. That&apos;s okay, you can purchase credits ala-carte or subscribe now!</p>
        </CardContent>
      ) : null}
      {subscription ? (
        <>
          <CardContent>
            <div className="border-t border-gray-100">
              <dl className="divide-y divide-gray-100">
                <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">Plan name</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{getPlanNameUsingPlanId(subscription.planId)}</dd>
                </div>
                <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">Price</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">${subscription.nextBillingPeriodAmount}</dd>
                </div>
                <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">Started</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{subscription.firstBillingDate ? formattedDate(subscription.firstBillingDate) : null}</dd>
                </div>
                <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">Remaining Balance</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">${subscription.balance}</dd>
                </div>
              </dl>
            </div>
          </CardContent>
          <CardFooter>
            <Button size={'sm'} variant={'link'} onClick={onModifySubscription}>
              Change Subscription
            </Button>
            <Button size={'sm'} variant={'link'} className="text-red-500">
              Cancel Subscription
            </Button>
          </CardFooter>
        </>
      ) : null}
    </Card>
  );
};

export default CurrentSubscription;
