import { Button } from '@/components/ui/button';
import { useGoogleAuth } from '../../utils/google/oAuth';

const ContinueWithGoogle = () => {
  const { initiateGoogleAuth } = useGoogleAuth();

  const login = async () => {
    await initiateGoogleAuth();
  };

  return (
    <>
      <Button onClick={login} className="w-full flex flex-row justify-start align-middle bg-[#4285F4] text-white">
        <img className="mr-2" height="20" width="20" alt="Google" src="https://cdn.simpleicons.org/google/white" />
        <span className="bg-white" style={{ width: '1px', height: '24px' }}></span>
        <span className="ml-2">Continue with Google</span>
      </Button>
    </>
  );
};

export default ContinueWithGoogle;
