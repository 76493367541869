import { useAuth } from '@context/AuthContext';
import { Menu, X } from 'lucide-react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const PublicHeader = () => {
  const { user } = useAuth();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className={`sticky top-0 ${isScrolled ? 'bg-gray-900/70 h-16' : 'bg-black h-20'} text-white transition-all duration-300 backdrop-blur-md shadow-md z-50`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-full">
        <div className="flex justify-between items-center h-full">
          <div className="flex items-center justify-start">
            <img src="/favicon-32x32.png" alt="Logo" className="h-8 w-8 mr-4" />
            <Link to="/" className="ml-2 text-xl font-bold hover:text-blue-400">
              {process.env.REACT_APP_NAME}
            </Link>
          </div>
          <nav className="hidden md:flex items-center">
            <Link to="/about" className="hover:text-blue-400 px-3 py-2 rounded-md text-sm font-medium">
              About
            </Link>
            <Link to="/faq" className="hover:text-blue-400 px-3 py-2 rounded-md text-sm font-medium">
              How it works
            </Link>
            {user ? (
              <Link to="/dashboard" className="hover:text-blue-400 px-3 py-2 rounded-md text-sm font-medium">
                Dashboard
              </Link>
            ) : (
              <Link to="/login" className="hover:text-blue-400 px-3 py-2 rounded-md text-sm font-medium">
                Get started
              </Link>
            )}
          </nav>
          <div className="md:hidden">
            <button onClick={toggleMenu} className="text-white focus:outline-none focus:ring-2 focus:ring-blue-400 rounded-md p-2">
              {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
        </div>
      </div>
      {/* Mobile menu */}
      {isMenuOpen && (
        <div className="md:hidden fixed inset-0 z-100 bg-gray-900/95 backdrop-blur-md">
          <div className="flex justify-between h-16 items-center p-4">
            <Link to="/" className="text-xl font-bold hover:text-blue-400" onClick={toggleMenu}>
              {process.env.REACT_APP_NAME}
            </Link>
            <button onClick={toggleMenu} className="text-white focus:outline-none focus:ring-2 focus:ring-blue-400 rounded-md p-2">
              <X size={24} />
            </button>
          </div>
          <div className="bg-gray-900/95 backdrop-blur-md">
            <nav className="px-4 pt-4 pb-8">
              <div className="space-y-4">
                <Link to="/about" className="hover:text-blue-400 block px-3 py-2 rounded-md text-lg font-medium" onClick={toggleMenu}>
                  About
                </Link>
                <Link to="/faq" className="hover:text-blue-400 block px-3 py-2 rounded-md text-lg font-medium" onClick={toggleMenu}>
                  How it works
                </Link>
                {user ? (
                  <Link to="/dashboard" className="hover:text-blue-400 block px-3 py-2 rounded-md text-lg font-medium" onClick={toggleMenu}>
                    Dashboard
                  </Link>
                ) : (
                  <Link to="/login" className="hover:text-blue-400 block px-3 py-2 rounded-md text-lg font-medium" onClick={toggleMenu}>
                    Get started
                  </Link>
                )}
              </div>
            </nav>
          </div>
        </div>
      )}
    </header>
  );
};

export default PublicHeader;
