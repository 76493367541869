import { useAuth } from '@context/AuthContext';
import useApi from '@hooks/useApi';
import { Purchase } from '@schema/purchase.interface';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

function Transactions() {
  const { user } = useAuth();
  const { request } = useApi();
  const [transactions, setTransactions] = useState<Purchase[]>([]);

  useEffect(() => {
    const getTransactions = async () => {
      const transactions = await request({ url: '/api/payments' });
      setTransactions(transactions);
    };

    getTransactions();
  }, [request]);

  return (
    <div className="min-h-screen">
      <main>
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          <div className="px-4 py-6 sm:px-0">
            <h2 className="text-2xl font-semibold">Hey, {user?.firstName}.</h2>
            <p className="mt-1 text-sm text-gray-600">
              Here are your previous transactions. If you have any questions, please
              <Link to="/contact" className="ps-1 font-medium text-indigo-600 hover:text-indigo-500">
                contact us
              </Link>
              .
            </p>

            <div className="mt-6">
              <h3 className="text-lg font-medium">Past Transactions</h3>
              {transactions.length > 0 ? (
                <ul className="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-3">
                  {transactions.map((transaction) => (
                    <li key={transaction._id} className="col-span-1 bg-white/70 backdrop-blur-md rounded-lg shadow divide-y divide-gray-200">
                      <div className="w-full flex items-center justify-between p-6 space-x-6">
                        <div className="flex-1 truncate">
                          <div className="flex items-center space-x-3">
                            <h3 className="text-sm font-medium truncate">{transaction.timestamp}</h3>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="-mt-px flex divide-x divide-gray-200">
                          <div className="w-0 flex-1 flex">
                            <Link
                              to={`/dashboard/builder/${transaction._id}`}
                              className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500">
                              Load
                            </Link>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="mt-3 text-sm text-gray-600">No transactions.</p>
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Transactions;
