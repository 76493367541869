import { LLMResponse } from '@schema/llm-response.interface';
import { PersonalInfo } from '@schema/personal-info.interface';
import MissingInformation from './ResumeStyles/MissingInformation';
import RedditRecommended from './ResumeStyles/RedditRecommended';
import SkillsSidebar from './ResumeStyles/SkillsSidebar';

export type ResumeLayout = 'skills-sidebar' | 'reddit-recommended';

interface TailoredResumeProps {
  personalInfo?: PersonalInfo;
  response?: LLMResponse;
  style: ResumeLayout;
}

const TailoredResume = ({ personalInfo, response, style }: TailoredResumeProps) => {
  if (!personalInfo || !response) {
    return <MissingInformation personalInfo={personalInfo} response={response} />;
  } else if (style === 'skills-sidebar') {
    return <SkillsSidebar personalInfo={personalInfo} response={response} />;
  } else {
    return <RedditRecommended personalInfo={personalInfo} response={response} />;
  }
};

export default TailoredResume;
