import { Document, Link, Page, Text, View } from '@react-pdf/renderer';
import { LLMResponse } from '@schema/llm-response.interface';
import { PersonalInfo } from '@schema/personal-info.interface';
import MapWorkExperienceComponent from './components/MapWorkExperienceComponent';
import SpaceBetween from './components/SpaceBetween';
import styles from './SharedStyles';

const RedditRecommended = ({ personalInfo, response }: { personalInfo: PersonalInfo; response?: LLMResponse }) => {
  // useEffect(() => {
  //   console.log(personalInfo);
  //   console.log(response);
  // }, [personalInfo, response]);

  const cleanUrl = (url: string) => {
    return url.replace(/(^\w+:|^)\/\/(www\.)?/, '');
  };

  return (
    <Document title={personalInfo.name + ' - ' + (response?.title ?? 'Resume')} author={personalInfo.name} subject="Resume">
      <Page size="A4" style={styles.page}>
        <View style={styles.content}>
          <Text style={styles.title}>{personalInfo.name}</Text>
          <Text style={styles.text}>
            {personalInfo.email.length ? (
              <Link style={styles.link} src={personalInfo.email}>
                {personalInfo.email}
              </Link>
            ) : null}
            {personalInfo.email.length && personalInfo.phone.length ? <Text style={styles.link}> | </Text> : null}
            {personalInfo.phone.length ? (
              <Link style={styles.link} src={`tel:${personalInfo.phone}`}>
                {personalInfo.phone}
              </Link>
            ) : null}
            {personalInfo.phone.length && personalInfo.location.length ? <Text style={styles.link}> | </Text> : null}
            {personalInfo.location}
          </Text>
          <Text style={[styles.text, { marginBottom: 5 }]}>
            {personalInfo.githubUrl.length ? (
              <Link style={styles.link} src={personalInfo.githubUrl}>
                {cleanUrl(personalInfo.githubUrl)}
              </Link>
            ) : null}
            {personalInfo.githubUrl.length && personalInfo.linkedinUrl.length ? <Text style={styles.link}> | </Text> : null}
            {personalInfo.linkedinUrl.length ? (
              <Link style={styles.link} src={personalInfo.linkedinUrl}>
                {cleanUrl(personalInfo.linkedinUrl)}
              </Link>
            ) : null}
          </Text>
          {response ? (
            <>
              <Text style={styles.heading}>Work Experience</Text>
              <MapWorkExperienceComponent response={response} />
              <Text style={styles.heading}>Education</Text>
              {response.education.map((edu, index) => (
                <View key={index}>
                  <SpaceBetween col1={edu.school} col2={edu.date} fontSize={13} fontWeight={900} />
                  <Text style={[styles.text, { fontStyle: 'italic' }]}>
                    {edu.degree}, {edu.major}
                  </Text>
                  {edu.honors ? <Text style={styles.li}>{edu.honors.map((honor, i) => '• ' + honor).join('\n')}</Text> : null}
                </View>
              ))}
              <Text style={styles.heading}>Licenses & Certifications</Text>
              {response.certifications.map((cert, index) => (
                <Text key={index} style={styles.li}>
                  {cert}
                </Text>
              ))}
              <Text style={styles.heading}>Skills</Text>
              <Text style={styles.text}>{response.skills.join('; ')}</Text>
            </>
          ) : (
            <Text>Load a Resume from history or enter a new job posting</Text>
          )}
        </View>
      </Page>
    </Document>
  );
};

export default RedditRecommended;
