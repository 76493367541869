import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { useCallback, useState } from 'react';

export interface ErrorResponse {
  error: string;
}

const useApi = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const request = useCallback(async (config: AxiosRequestConfig) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios({
        ...config,
        headers: {
          ...config.headers,
          'x-auth-token': localStorage.getItem('token'),
        },
      })
        .catch((err) => {
          throw err;
        })
        .finally(() => setLoading(false));

      return response.data;
    } catch (error: unknown) {
      const err = error as AxiosError;
      if (err.isAxiosError) {
        if (err.status === 413) {
          setError('File size too large');
        } else if (err.response) {
          setError((err.response.data as ErrorResponse).error || 'An error occurred');
        } else if (err.request) {
          setError('No response received from server');
        } else {
          setError('Error setting up the request');
        }
      } else {
        // Handle non-AxiosError errors
        setError('An unexpected error occurred');
      }

      setLoading(false);
      throw err;
    }
  }, []);

  return { request, loading, error };
};

export default useApi;
