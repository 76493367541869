import { Button } from '@/components/ui/button';
import { InputWithLabel } from '@/components/ui/input-with-label';
import { Spinner } from '@/components/ui/spinner';
import { useAuth } from '@context/AuthContext';
import { useError } from '@context/ErrorContext';
import useApi from '@hooks/useApi';
import { PersonalInfo } from '@schema/personal-info.interface';
import { Save } from 'lucide-react';
import React, { useState } from 'react';

interface ModifyPersonalInformationComponentProps {
  onSetPersonalInfo: (info: PersonalInfo) => void;
}

const ModifyPersonalInformationComponent = ({ onSetPersonalInfo }: ModifyPersonalInformationComponentProps) => {
  const { loading, request } = useApi();
  const { user, setUser } = useAuth();
  const { setError } = useError();
  const [personalInfo, setPersonalInfo] = useState<PersonalInfo>({
    name: user?.name ?? '',
    email: user?.email ?? '',
    phone: user?.phone ?? '',
    location: user?.location ?? '',
    githubUrl: user?.githubUrl ?? '',
    linkedinUrl: user?.linkedinUrl ?? '',
  });

  /**
   * Updates the personal info state when the user types in the input fields.
   * @param e - The input change event.
   */
  const handlePersonalInfoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === 'phone') {
      const formattedPhoneNumber = formatPhoneNumber(value);
      setPersonalInfo({ ...personalInfo, [name]: formattedPhoneNumber });
    } else {
      setPersonalInfo({ ...personalInfo, [name]: value });
    }
  };

  /**
   * Formats the phone number as the user types it in.
   * @param phoneNumber - The phone number to format.
   * @returns The formatted phone number.
   */
  const formatPhoneNumber = (phoneNumber: string) => {
    const cleaned = phoneNumber.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return phoneNumber;
  };

  /**
   * Saves the master resume to the server and local storage.
   */
  const handleOnSubmit = async () => {
    try {
      // Save master resume to server
      await request({
        url: '/api/users/personal-info',
        method: 'POST',
        data: personalInfo,
      });

      // Update the user object with the new personal info
      if (user) {
        setUser({ ...user, ...personalInfo });
      }

      onSetPersonalInfo(personalInfo);
    } catch (error) {
      console.error(error);
      setError('Failed to save personal information. Please try again later.');
    }
  };

  return (
    <div className="mt-6 space-y-4 bg-white p-8 shadow-lg rounded-lg flex flex-col">
      <h2 className="text-2xl font-semibold">Your Personal Information</h2>
      <div className="space-y-4 w-full">
        <p className="text-sm text-gray-500">
          Fill in your personal information below. <strong>This information is not sent to the LLM</strong> but will be used to populate the resume template.
        </p>
      </div>
      <InputWithLabel label="Name" value={personalInfo.name} onChange={handlePersonalInfoChange} name="name" />
      <InputWithLabel label="Email" value={personalInfo.email} onChange={handlePersonalInfoChange} name="email" type="email" />
      <InputWithLabel label="Phone" value={personalInfo.phone} onChange={handlePersonalInfoChange} name="phone" />
      <InputWithLabel label="Location" value={personalInfo.location} onChange={handlePersonalInfoChange} name="location" />
      <InputWithLabel label="GitHub URL" value={personalInfo.githubUrl} onChange={handlePersonalInfoChange} name="githubUrl" icon="/assets/brand-icons/github.svg" />
      <InputWithLabel label="LinkedIn URL" value={personalInfo.linkedinUrl} onChange={handlePersonalInfoChange} name="linkedinUrl" icon="/assets/brand-icons/linkedin.svg" />

      <Button onClick={handleOnSubmit} disabled={loading} className="w-full" variant={'rainbowGlow'}>
        <div className="flex items-center">
          {loading ? <Spinner className="mr-2 h-4 w-4" /> : <Save className="mr-2 h-4 w-4" />} <span>Save</span>
        </div>
      </Button>
    </div>
  );
};

export default ModifyPersonalInformationComponent;
