import { User } from '@schema/user.interface';
import axios from 'axios';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

interface AuthContextSchema {
  user: User | null;
  setUser: (user: React.SetStateAction<User | null>) => void;
  loading: boolean;
  logout: () => void;
  getMe: () => void;
}

const AuthContext = createContext<AuthContextSchema>({
  user: null,
  setUser: () => void 0,
  loading: true,
  logout: () => void 0,
  getMe: () => void 0,
});

export function AuthProvider({ children }: { children: ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  const getMe = async () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        setLoading(true);
        const res = await axios
          .get('/api/users/me', {
            headers: { 'x-auth-token': token },
          })
          .finally(() => setLoading(false));
        setUser(res.data);
      } catch (error) {
        // console.error('Error loading user', error);
        localStorage.removeItem('token');
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMe();
  }, []);

  const logout = () => {
    localStorage.removeItem('token');
    setUser(null);
  };

  return <AuthContext.Provider value={{ user, setUser, getMe, loading, logout }}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return useContext(AuthContext);
}
