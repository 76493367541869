import { Button } from '@/components/ui/button';
import { motion } from 'framer-motion';
import { Briefcase, Cpu, Download, FileText, User } from 'lucide-react';
import React from 'react';
import { Link } from 'react-router-dom';

const HowItWorks: React.FC = () => {
  const steps = [
    {
      icon: <User size={40} />,
      title: 'Enter Your Personal Information',
      description: 'Start by providing your basic details such as name, contact information, and links to your social media profiles.',
    },
    {
      icon: <FileText size={40} />,
      title: 'Create Your Master Resume',
      description: 'Input your entire work history, education, achievements, and honors. This comprehensive resume will be the foundation for all your job applications.',
    },
    {
      icon: <Briefcase size={40} />,
      title: 'Find a Job Post',
      description: "When you're ready to apply for a job, simply copy and paste the job posting into our system.",
    },
    {
      icon: <Cpu size={40} />,
      title: 'AI Analysis and Tailoring',
      description:
        'Our advanced AI analyzes the job post and compares it to your master resume. It then creates a unique, tailored resume that highlights your most relevant experiences and skills for that specific job.',
    },
    {
      icon: <Download size={40} />,
      title: 'Get Your Tailored Application',
      description: "Download your personalized resume, along with a matching cover letter and icebreaker message, all optimized for the job you're applying to.",
    },
  ];

  return (
    <div className="min-h-screen bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-4xl font-bold text-center mb-12">How It Works</h1>

        {steps.map((step, index) => (
          <motion.div key={index} className="bg-white rounded-lg shadow-md p-6 mb-8" initial={{ opacity: 0, y: 50 }} animate={{ opacity: 1, y: 0 }} transition={{ delay: index * 0.2 }}>
            <div className="flex items-center mb-4">
              <div className="bg-blue-100 rounded-full p-3 mr-4">{step.icon}</div>
              <h2 className="text-2xl font-semibold">{step.title}</h2>
            </div>
            <p className="text-gray-600">{step.description}</p>
          </motion.div>
        ))}

        <motion.div
          className="bg-gray-950 text-white rounded-lg shadow-md p-6 mt-12"
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ delay: steps.length * 0.2 }}>
          <h2 className="text-2xl font-semibold mb-4">Ready to Get Started?</h2>
          <p className="mb-4">Create your master resume once, and use it to apply to multiple jobs with tailored applications. Save time and increase your chances of landing your dream job!</p>
          <p className="text-center">
            <Link to="/login">
              <Button variant={'rainbowGlow'} size={'lg'}>
                Create Your Master Resume
              </Button>
            </Link>
          </p>
        </motion.div>
      </div>
    </div>
  );
};

const FAQPage = () => (
  <>
    <div className="bg-black min-h-[30vh]">
      <main className="flex flex-col items-center justify-center text-center px-4 py-20">
        <h1 className="text-5xl text-white font-bold mb-4">FAQ</h1>
        <p className="text-xl mb-8 text-white max-w-2xl">It&apos;s so... Simple!</p>
      </main>
    </div>

    <HowItWorks />
  </>
);

export default FAQPage;
