import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { DragDropContext, Draggable, Droppable, DropResult } from '@hello-pangea/dnd';
import ModalComponent from '@pages/components/ModalComponent';
import { GripVertical, ScrollText, X } from 'lucide-react';
import { useEffect, useState } from 'react';

interface ModifyCertificationsComponentProps {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  items: string[];
  onSet: (certifications: string[]) => void;
}

const ModifyCertificationsComponent = ({ isVisible, setIsVisible, items, onSet }: ModifyCertificationsComponentProps) => {
  const [certifications, setCertifications] = useState<string[]>([]);

  /**
   * Load the certification items from the items prop when the component mounts.
   */
  useEffect(() => {
    setCertifications(items);
  }, [items]);

  /**
   * Adds a new certification item to the list.
   *
   * @returns {void}
   */
  const addCertification = () => {
    setCertifications([...certifications, '']);
  };

  /**
   * Removes an certification item from the list.
   *
   * @param {number} index The index of the certification item to remove.
   * @returns {void}
   */
  const removeCertification = (index: number) => {
    const updatedCertifications = [...certifications];
    updatedCertifications.splice(index, 1);
    setCertifications(updatedCertifications);
  };

  /**
   * Updates a value in an certification item.
   *
   * @param {number} index The index of the certification item.
   * @param {string} key The key of the value to change.
   * @param {string | string[]} value The new value of the key.
   * @returns {void}
   */
  const handleCertificationChange = (index: number, value: string) => {
    const updatedCertifications = [...certifications];
    updatedCertifications[index] = value;
    setCertifications(updatedCertifications);
  };

  /**
   * Submits the certification items to the parent component.
   *
   * @returns {void}
   */
  const handleSubmit = () => {
    onSet(certifications);
    setIsVisible(false);
  };

  /**
   * Rearranges the certification items when the user drags and drops an item.
   *
   * @param {DropResult} result The result of the drag and drop operation.
   * @returns {void}
   */
  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const updatedCertification = [...certifications];
    const [reorderedItem] = updatedCertification.splice(result.source.index, 1);
    updatedCertification.splice(result.destination.index, 0, reorderedItem);

    setCertifications(updatedCertification);
  };

  return (
    <ModalComponent
      title="Your Certifications"
      subtitle="Add, edit, or remove your certifications that will appear on your resume."
      isModalOpen={isVisible}
      setIsModalOpen={setIsVisible}
      onSubmit={handleSubmit}
      submitBtnText="Save Certifications"
      loading={false}
      extraButtons={
        <Button variant="default" onClick={addCertification}>
          Add Another
        </Button>
      }
      icon={<ScrollText className="h-6 w-6" />}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="certification-list">
          {(provided) => (
            <div className="space-y-2" {...provided.droppableProps} ref={provided.innerRef} style={{ maxHeight: '50vh', overflowY: 'auto' }}>
              {certifications.map((item, index) => (
                <Draggable key={index} draggableId={index.toString()} index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{ ...provided.draggableProps.style, left: 'auto !important', top: 'auto !important' }}>
                      <div key={index} className="flex justify-start items-center space-x-2">
                        <GripVertical className="h-6 w-6 hover:cursor-grab" />
                        <Input id={`certification-${index}`} name={`certification-${index}`} value={item} onChange={(e) => handleCertificationChange(index, e.target.value)} />
                        <Button className="px-2" onClick={() => removeCertification(index)}>
                          <X className="h-6 w-6" />
                        </Button>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </ModalComponent>
  );
};

export default ModifyCertificationsComponent;
