import { useAuth } from '@context/AuthContext';
import { useSidebar } from '@context/SidebarContext';
import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import LLMSettingsComponent from './LLMSettingsComponent';

const AdminHeader = () => {
  const { className } = useSidebar();
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const handleLogout = async () => {
    await logout();
    navigate('/login');
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <header className={`absolute top-0 bg-white/70 backdrop-blur-md shadow-md z-50 ${className}`}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center py-6">
            <div className="flex items-center">
              <img src="/favicon-32x32.png" alt="Logo" className="h-8 w-8 mr-4" />
              <Link to="/" className="ml-2 text-xl font-bold hover:text-blue-400">
                {process.env.REACT_APP_NAME}
              </Link>
            </div>
            <nav className="flex items-center">
              <Link to="/admin" className=" hover:text-blue-400 px-3 py-2 rounded-md text-sm font-medium">
                Dashboard
              </Link>
              <div className="ml-3 relative" ref={dropdownRef}>
                <div>
                  <button type="button" className="bg-transparent flex text-sm h-[32px] items-center" id="user-menu" aria-expanded="false" aria-haspopup="true" onClick={toggleDropdown}>
                    <span className="sr-only">Open user menu</span>
                    <div className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                      {user?.picture ? (
                        <img className="h-8 w-8 rounded-full" src={user.picture} alt="" />
                      ) : (
                        <svg className="h-8 w-8 rounded-full" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M10 12a4 4 0 100-8 4 4 0 000 8zM2 16a8 8 0 1116 0 8 8 0 01-16 0zm10-2a2 2 0 11-4 0 2 2 0 014 0z" />
                        </svg>
                      )}
                    </div>
                    <span className="hover:text-blue-400 ps-2 text-sm font-medium">{user?.firstName ? 'Welcome, ' + user.firstName + '!' : ''}</span>
                  </button>
                </div>
                {isDropdownOpen && (
                  <div
                    className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="user-menu">
                    <button
                      onClick={() => {
                        setIsDropdownOpen(false);
                        handleLogout();
                      }}
                      className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      role="menuitem">
                      Sign out
                    </button>

                    <hr />

                    {user?.role === 'admin' && (
                      <>
                        <button
                          onClick={() => {
                            setIsDropdownOpen(false);
                            setIsSettingsOpen(true);
                          }}
                          className="block w-full text-left px-4 py-2 text-sm text-orange-700 hover:bg-orange-100"
                          role="menuitem">
                          LLM Settings
                        </button>
                      </>
                    )}
                  </div>
                )}
              </div>
            </nav>
          </div>
        </div>
      </header>
      <LLMSettingsComponent isVisible={isSettingsOpen} setIsVisible={setIsSettingsOpen} />
    </>
  );
};

export default AdminHeader;
