import { useAuth } from '@context/AuthContext';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ContinueWithGoogle from './components/ContinueWithGoogle';
import ContinueWithLinkedIn from './components/ContinueWithLinkedIn';

function Login() {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth();

  /**
   * Redirect to dashboard if user is already logged in
   */
  useEffect(() => {
    if (user) {
      console.log('User logged in, navigating to dashboard');
      navigate('/dashboard', { replace: true });
    }
  }, [user, navigate]);

  /**
   * Check for OAuth token in URL query params. If found, save it to local storage and redirect to dashboard
   */
  useEffect(() => {
    console.log('Checking for OAuth token...');
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');

    if (token) {
      console.log('OAuth token found, logging in...');
      localStorage.setItem('token', token);
      navigate('/dashboard', { replace: true });
    }
  }, [location, navigate]);

  return (
    <div className="min-h-screen flex items-start justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-2">
        <h2 className="mt-6 text-center text-3xl font-extrabold">Sign in to your account</h2>
        <p>You can create an account or sign in with Google or LinkedIn.</p>
        <ContinueWithGoogle />
        <ContinueWithLinkedIn />
      </div>
    </div>
  );
}

export default Login;
