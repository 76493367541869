import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Label } from '@/components/ui/label';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { useAuth } from '@context/AuthContext';
import { useLoading } from '@context/LoadingContext';
import useApi from '@hooks/useApi';
import { Purchase } from '@schema/purchase.interface';
import { AlertCircle } from 'lucide-react';
import { useEffect, useState } from 'react';
import BraintreeDropin from './components/BraintreeDropin';

const CreditsExplanation = () => {
  return (
    <Alert variant="default" className="mb-4">
      <AlertCircle className="h-4 w-4" />
      <AlertTitle>Why do we charge credits?</AlertTitle>
      <AlertDescription>
        Creating high-quality resumes requires significant computational resources. Our advanced AI models, secure data processing, and continuous improvements all contribute to operational costs. By
        using a credit system, we&apos;re able to:
        <ul className="list-disc pl-5 mt-2">
          <li>Maintain powerful servers for quick resume generation</li>
          <li>Regularly update our AI models with the latest job market trends</li>
          <li>Ensure your data is processed securely and privately</li>
          <li>Provide customer support and ongoing improvements</li>
        </ul>
        Your purchase of credits helps us continue offering a top-notch resume creation service while keeping the per-use cost affordable. We appreciate your support in helping us maintain and improve
        this valuable tool for your career success!
      </AlertDescription>
    </Alert>
  );
};

const creditBundles: CreditBundle[] = [
  { id: '10', credits: 10, price: 1 },
  { id: '20', credits: 20, price: 2 },
  { id: '50', credits: 50, price: 5 },
  { id: '100', credits: 100, price: 10 },
];

export interface CreditBundle {
  id: string;
  credits: number;
  price: number;
}

const CreditPurchase = () => {
  const { user } = useAuth();
  const { loading, request } = useApi();
  const { setIsLoading } = useLoading();
  const [selectedBundle, setSelectedBundle] = useState(creditBundles[0]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading, setIsLoading]);

  const handleBundleChange = (bundleId: string) => {
    const bundle = creditBundles.find((b) => b.id === bundleId);
    if (bundle) setSelectedBundle(bundle);
    else console.error('Invalid bundle ID:', bundleId);
  };
  const handlePurchase = (purchase: Purchase) => {
    // Logic to handle the purchase
    console.log(`Purchasing ${purchase.credits} credits for $${purchase.amount}`);
  };

  const justGiveMe10Credits = async () => {
    setIsLoading(true);
    const response: { credits: number } = await request({ method: 'get', url: '/api/payments/just-gimme-some' }).finally(() => setIsLoading(false));
    if (response.credits && user) user.credits += response.credits;
  };

  return (
    <div className="min-h-screen bg-gray-100 w-full">
      <main className="container mx-auto px-4 py-4">
        <div className="w-1/2 mx-auto flex justify-center">
          <CreditsExplanation />
        </div>

        <Card className="w-[350px] mx-auto space-y-2">
          <CardHeader>
            <CardTitle>Purchase Credits</CardTitle>
            <CardDescription>Select a credit bundle to purchase</CardDescription>
          </CardHeader>
          <CardContent>
            <RadioGroup onValueChange={handleBundleChange} defaultValue={selectedBundle.id}>
              {creditBundles.map((bundle) => (
                <div key={bundle.id} className="flex items-center space-x-2">
                  <RadioGroupItem value={bundle.id} id={`bundle-${bundle.id}`} />
                  <Label htmlFor={`bundle-${bundle.id}`}>
                    {bundle.credits} credits for ${bundle.price}
                  </Label>
                </div>
              ))}
            </RadioGroup>
            <BraintreeDropin bundle={selectedBundle} onHandlePurchase={handlePurchase} />
          </CardContent>
          {process.env.REACT_APP_ENV === 'localhost' && (
            <CardFooter>
              <Button onClick={justGiveMe10Credits} variant={'rainbowGlow'}>
                Or, just gimme some (10)!
              </Button>
            </CardFooter>
          )}
        </Card>
      </main>
    </div>
  );
};

export default CreditPurchase;
