import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { InputWithLabel } from '@/components/ui/input-with-label';
import { DragDropContext, Draggable, Droppable, DropResult } from '@hello-pangea/dnd';
import ModalComponent from '@pages/components/ModalComponent';
import { Label } from '@radix-ui/react-label';
import { Experience } from '@schema/llm-response.interface';
import { Brain, GripVertical, Trash, X } from 'lucide-react';
import { useEffect, useState } from 'react';

const ExperienceItem = ({
  index,
  item,
  onRemove,
  onChange,
  onEditing,
}: {
  /**
   * The index of the experience item.
   */
  index: number;

  /**
   * The experience item.
   */
  item: Experience;

  /**
   * Occurs when the user clicks the remove button on the experience item.
   *
   * @param {number} index The index of the experience item.
   * @returns {void}
   */
  onRemove: (index: number) => void;

  /**
   * Occurs when the user changes a value in the experience item.
   *
   * @param {number} index The index of the experience item.
   * @param {string} key The key of the value to change.
   * @param {string | string[]} value The new value of the key.
   * @returns {void}
   */
  onChange: (index: number, key: string, value: string | string[]) => void;

  /**
   * Occurs when the user starts or stops editing the education item.
   *
   * @param {boolean} editing Whether the user is editing the education item.
   * @returns {void}
   */
  onEditing: (editing: boolean) => void;
}) => {
  const [editing, setEditing] = useState(false);
  /**
   * Notify the parent component when the user starts or stops editing the experience item
   */
  useEffect(() => {
    onEditing(editing);
  }, [editing, onEditing]);

  /**
   * Removes an responsibility from the experience item.
   *
   * @param {number} i The index of the responsibility to remove.
   */
  const removeResponsibilities = (i: number) => {
    const updatedResponsibilities = [...item.responsibilities];
    updatedResponsibilities.splice(i, 1);
    onChange(index, 'responsibilities', updatedResponsibilities);
  };

  /**
   * Adds an responsibility to the experience item.
   */
  const addResponsibilities = () => {
    const updatedResponsibilities = [...item.responsibilities, ''];
    onChange(index, 'responsibilities', updatedResponsibilities);
  };

  /**
   * Edits an responsibility in the experience item.
   *
   * @param {number} i The index of the responsibility to edit.
   * @param {string} value The new value of the responsibility.
   */
  const editResponsibilities = (i: number, value: string) => {
    const updatedResponsibilities = [...item.responsibilities];
    updatedResponsibilities[i] = value;
    onChange(index, 'responsibilities', updatedResponsibilities);
  };

  /**
   * Rearranges the responsibilities when the user drags and drops an item.
   *
   * @param {DropResult} result The result of the drag and drop operation.
   * @returns {void}
   */
  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const updatedResponsibilities = [...item.responsibilities];
    const [reorderedItem] = updatedResponsibilities.splice(result.source.index, 1);
    updatedResponsibilities.splice(result.destination.index, 0, reorderedItem);
    onChange(index, 'responsibilities', updatedResponsibilities);
  };

  return (
    <Card key={index}>
      {!editing ? (
        <CardHeader>
          <CardTitle className="flex justify-start gap-2">{item.title}</CardTitle>
          <CardDescription className="flex justify-between">
            <span>{item.company}</span>
            <span>{item.date}</span>
          </CardDescription>
          <CardDescription>{item.location}</CardDescription>
        </CardHeader>
      ) : (
        <>
          <CardHeader>
            <CardTitle className="flex justify-start gap-2">Experience #{index + 1}</CardTitle>
          </CardHeader>
          <CardContent className="space-y-2">
            <InputWithLabel label={`Title`} inline value={item.title} onChange={(e) => onChange(index, 'title', e.target.value)} />
            <InputWithLabel label={`Location`} inline value={item.location} onChange={(e) => onChange(index, 'location', e.target.value)} />
            <InputWithLabel label={`Company`} inline value={item.company} onChange={(e) => onChange(index, 'company', e.target.value)} />
            <InputWithLabel label={`Date`} inline value={item.date} onChange={(e) => onChange(index, 'date', e.target.value)} />

            {item.responsibilities.length > 0 && (
              <div className="">
                <Label htmlFor={`experience-${index}`} className="font-medium">
                  Responsibilities
                </Label>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="responsibilities-list">
                    {(provided) => (
                      <div className="space-y-2" {...provided.droppableProps} ref={provided.innerRef}>
                        {item.responsibilities.map((responsibility, i) => (
                          <Draggable key={i} draggableId={i.toString()} index={i}>
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{ ...provided.draggableProps.style, left: 'auto !important', top: 'auto !important' }}>
                                <div key={i} className="flex justify-start items-center space-x-2">
                                  <GripVertical className="h-6 w-6 hover:cursor-grab" />
                                  <Input
                                    id={`experience-${index}-responsibility-${i}`}
                                    name={`experience-${index}-responsibility-${i}`}
                                    value={responsibility}
                                    onChange={(e) => editResponsibilities(i, e.target.value)}
                                  />
                                  <Button className="px-2" onClick={() => removeResponsibilities(i)}>
                                    <X className="h-6 w-6" />
                                  </Button>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            )}
          </CardContent>
        </>
      )}

      <CardFooter className="flex justify-between bg-gray-200 p-2">
        <Button variant="link" size="icon" onClick={() => onRemove(index)}>
          <Trash className="h-6 w-6" />
        </Button>
        <div className="space-x-2">
          {editing && (
            <Button variant="default" onClick={addResponsibilities}>
              Add Responsibilities
            </Button>
          )}
          <Button variant="default" onClick={() => setEditing(!editing)}>
            {editing ? 'Done' : 'Edit'}
          </Button>
        </div>
      </CardFooter>
    </Card>
  );
};

interface ModifyExperienceComponentProps {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  items: Experience[];
  onSet: (experience: Experience[]) => void;
}

const ModifyExperienceComponent = ({ isVisible, setIsVisible, items, onSet }: ModifyExperienceComponentProps) => {
  const [editing, setEditing] = useState(false);
  const [experience, setExperience] = useState<Experience[]>([]);

  /**
   * Load experience items from the items prop when the component mounts.
   */
  useEffect(() => {
    setExperience(items);
  }, [items]);

  /**
   * Adds an experience item.
   *
   * @returns {void}
   */
  const addExperience = () => {
    setExperience([...experience, { title: '', location: '', company: '', date: '', responsibilities: [] }]);
  };

  /**
   * Removes an experience item.
   *
   * @param {number} index The index of the experience item.
   * @returns {void}
   */
  const removeExperience = (index: number) => {
    const updatedExperience = [...experience];
    updatedExperience.splice(index, 1);
    setExperience(updatedExperience);
  };

  /**
   * Updates the experience item when the user changes a value in the experience item.
   *
   * @param {number} index The index of the experience item.
   * @param {string} key The key of the value to change.
   * @param {string | string[]} value The new value of the key.
   * @returns {void}
   */
  const handleExperienceChange = (index: number, key: string, value: string | string[]) => {
    const updatedExperience = [...experience];
    updatedExperience[index][key] = value;
    setExperience(updatedExperience);
  };

  /**
   * Notifies the parent component when the user starts or stops editing the experience items.
   *
   * @returns {void}
   */
  const handleSubmit = () => {
    onSet(experience);
    setIsVisible(false);
  };

  /**
   * Rearranges the experience items when the user drags and drops an item.
   *
   * @param {DropResult} result The result of the drag and drop operation.
   * @returns {void}
   */
  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const updatedExperience = [...experience];
    const [reorderedItem] = updatedExperience.splice(result.source.index, 1);
    updatedExperience.splice(result.destination.index, 0, reorderedItem);

    setExperience(updatedExperience);
  };

  return (
    <ModalComponent
      title="Your Experience"
      subtitle="Add, edit, or remove your experience that will appear on your resume."
      isModalOpen={isVisible}
      setIsModalOpen={setIsVisible}
      onSubmit={handleSubmit}
      submitBtnText="Save Experience"
      loading={false}
      extraButtons={
        <Button variant="default" onClick={addExperience}>
          Add Another
        </Button>
      }
      icon={<Brain className="h-6 w-6" />}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="experience-list">
          {(provided) => (
            <div className="space-y-2" {...provided.droppableProps} ref={provided.innerRef} style={{ maxHeight: '50vh', overflowY: 'auto' }}>
              {experience.map((item, index) => (
                <Draggable key={index} draggableId={index.toString()} index={index} isDragDisabled={editing}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{ ...provided.draggableProps.style, left: 'auto !important', top: 'auto !important' }}>
                      <ExperienceItem index={index} item={item} onRemove={removeExperience} onChange={handleExperienceChange} onEditing={setEditing} />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </ModalComponent>
  );
};

export default ModifyExperienceComponent;
