import { Button } from '@/components/ui/button';
import ModalComponent from '@pages/components/ModalComponent';
import { LLMResponse } from '@schema/llm-response.interface';
import { Check, Copy, Paperclip } from 'lucide-react';
import { useEffect, useState } from 'react';

interface CoverLetterComponentProps {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  response: LLMResponse;
}

const CoverLetterComponent = ({ isVisible, setIsVisible, response }: CoverLetterComponentProps) => {
  const [isCopied, setIsCopied] = useState(false);

  /**
   * Copy the cover letter to the clipboard.
   */
  const copyCoverLetter = () => {
    navigator.clipboard.writeText(response.coverLetter);
    setIsCopied(true);
  };

  /**
   * Clear the 'copied' state after 3 seconds.
   */
  useEffect(() => {
    if (isCopied) {
      const timeout = setTimeout(() => {
        setIsCopied(false);
      }, 3000);

      return () => clearTimeout(timeout);
    }
  }, [isCopied]);

  return (
    <ModalComponent
      title="Cover Letter"
      subtitle="Include this cover letter while applying for the position."
      isModalOpen={isVisible}
      setIsModalOpen={setIsVisible}
      hasSubmit={false}
      loading={false}
      icon={<Paperclip className="h-6 w-6" />}
      extraButtons={
        <Button variant="rainbowGlow" onClick={copyCoverLetter}>
          {isCopied && <Check className="h-6 w-6" />}
          {!isCopied && <Copy className="h-6 w-6" />}
        </Button>
      }>
      <pre className="text-wrap">{response.coverLetter}</pre>
    </ModalComponent>
  );
};

export default CoverLetterComponent;
