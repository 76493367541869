import { Toaster } from '@/components/ui/toaster';
import { useAuth } from '@context/AuthContext';
import { HistoryProvider } from '@context/HistoricalContext';
import { SidebarProvider } from '@context/SidebarContext';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import PrivateHeader from './components/PrivateHeader';
import CreditPurchase from './CreditPurchase';
import Dashboard from './Dashboard';
import Profile from './Profile';
import ResumeBuilder from './ResumeBuilder/ResumeBuilder';
import SubscriptionPage from './Subscription';
import Transactions from './Transactions';
import './PrivateRoutes.css';

const PrivateRoutes = () => {
  const { user, loading } = useAuth();
  const location = useLocation();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return (
    <div id="dashboard">
      <SidebarProvider>
        <PrivateHeader />

        <HistoryProvider>
          <Routes>
            <Route index element={<Dashboard />} />
            <Route path="/builder/:id?" element={<ResumeBuilder />} />
            <Route path="/buy" element={<CreditPurchase />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/transactions" element={<Transactions />} />
            <Route path="/subscription" element={<SubscriptionPage />} />
            <Route path="*" element={<Navigate to="/dashboard" replace />} />
          </Routes>
        </HistoryProvider>
      </SidebarProvider>

      <Toaster />
    </div>
  );
};

export default PrivateRoutes;
