import { ArrowLeftCircle } from 'lucide-react';
import { useEffect } from 'react';
import CustomLink from './CustomLink';

const LegalDocument = ({ title, children }: { title: string; children: React.ReactNode }) => {
  // useEffect hook to scroll to the top when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-gray-100">
      <nav className="container mx-auto px-4 py-4">
        <CustomLink rainbowFlow={true} to="/">
          <ArrowLeftCircle size={24} className="inline-block -mt-1 mr-2" />
          Back to Home
        </CustomLink>
      </nav>

      <main className="container mx-auto px-4 py-12">
        <h1 className="text-4xl font-bold mb-8 text-center">{title}</h1>
        <div className="bg-white rounded-lg shadow-md p-8">{children}</div>
      </main>
    </div>
  );
};

export default LegalDocument;
