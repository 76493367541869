import { Button } from '@/components/ui/button';
import { Label } from '@/components/ui/label';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Spinner } from '@/components/ui/spinner';
import { useAuth } from '@context/AuthContext';
import { useHistory } from '@context/HistoricalContext';
import { useLoading } from '@context/LoadingContext';
import { useMobile } from '@context/MobileContext';
import useApi from '@hooks/useApi';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { LLMResponse } from '@schema/llm-response.interface';
import { PersonalInfo } from '@schema/personal-info.interface';
import { Resume } from '@schema/resume.interface';
import { Download, Flame, Layout, Paperclip, Save } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CoverLetterComponent from './components/CoverLetterComponent';
import IceBreakerComponent from './components/IceBreakerComponent';
import ModifyMasterResumeComponent from './components/ModifyMasterResumeComponent';
import ModifyPersonalInformationComponent from './components/ModifyPersonalInformationComponent';
import SetJobPostingComponent from './components/SetJobPostingComponent';
import ResponseEditor from './ResponseEditor';
import TailoredResume, { ResumeLayout } from './TailoredResume';

const ResumeBuilder = () => {
  const { isMobile } = useMobile();
  const { id } = useParams();
  const { user } = useAuth();
  const { history } = useHistory();
  const { loading, request } = useApi();
  const { setIsLoading } = useLoading();
  const [changed, setChanged] = useState<boolean>(false);
  const [llmResponse, setLlmResponse] = useState<LLMResponse | undefined>(undefined);
  const [personalInfo, setPersonalInfo] = useState<PersonalInfo | undefined>(undefined);
  const [masterResume, setMasterResume] = useState<string | undefined>(undefined);
  const [resumeLayout, setResumeLayout] = useState<ResumeLayout>('reddit-recommended');
  const [humanReadableResumeLayout, setHumanReadableResumeLayout] = useState<string>('Reddit Recommended');
  const [isCoverLetterOpen, setIsCoverLetterOpen] = useState(false);
  const [isIcebreakerMessageOpen, setIsIcebreakerMessageOpen] = useState(false);
  const [document, setDocument] = useState<JSX.Element | null>(null);

  useEffect(() => {
    if (llmResponse && personalInfo) {
      setDocument(<TailoredResume personalInfo={personalInfo} response={llmResponse} style={resumeLayout} />);
    }
  }, [llmResponse, personalInfo, resumeLayout]);

  const getButtonVariant = () => {
    if (isMobile) {
      return 'ghost';
    }

    return 'default';
  };

  useEffect(() => {
    if (resumeLayout === 'skills-sidebar') {
      setHumanReadableResumeLayout('Skills Sidebar');
    } else {
      setHumanReadableResumeLayout('Reddit Recommended');
    }
  }, [resumeLayout]);

  /**
   * Fetch the resume if the user navigated to the resume builder from the dashboard.
   */
  useEffect(() => {
    if (id && history.length > 0) {
      // console.log(`Finding resume (${id}) in history...`);
      const target = history.find((resume) => resume._id === id);

      if (target) {
        // console.log('Found resume:', target);

        const fetchAnalysis = async () => {
          const response = await request({
            url: `/api/resume/analysis/${id}`,
          });

          // console.log(response);
          setLlmResponse(response);

          setPersonalInfo({
            name: user?.name ?? '',
            email: user?.email ?? '',
            phone: user?.phone ?? '',
            location: user?.location ?? '',
            githubUrl: user?.githubUrl ?? '',
            linkedinUrl: user?.linkedinUrl ?? '',
          });
        };

        fetchAnalysis();
      }
    }
  }, [id, history, request, user]);

  /**
   * Set the loading state based on the API request.
   */
  useEffect(() => {
    setIsLoading(loading);
  }, [loading, setIsLoading]);

  /**
   * Updates the LLM response state and sets the changed state to true if the user made changes.
   *
   * @param {LLMResponse} response The latest LLM response.
   * @returns {void}
   */
  const handleChanges = (response?: LLMResponse) => {
    setChanged(true);
    setLlmResponse(response);
  };

  /**
   * Updates the resume with the latest changes.
   *
   * @returns {void}
   */
  const updateResumeById = async () => {
    await request({
      method: 'PUT',
      url: `/api/resume/${id}`,
      data: llmResponse,
    }).then((response: Resume) => {
      setLlmResponse(response.resume);
      setChanged(false);
    });
  };

  /**
   * Get the downloaded resume file name. Should be a unix timestamp followed by the user's name.
   *
   * @returns {string} The file name.
   */
  const getFileName = () => {
    return `${Date.now()}-${personalInfo?.name ?? 'resume'}.pdf`;
  };

  return (
    <div className="flex min-h-screen flex-grow">
      {llmResponse && <ResponseEditor response={llmResponse} onSetResponse={handleChanges} />}

      <main className={`mx-auto min-h-screen flex flex-col ${llmResponse ? 'w-full' : 'w-1/2'}`}>
        {!llmResponse && (
          <>
            {!personalInfo && <ModifyPersonalInformationComponent onSetPersonalInfo={setPersonalInfo} />}
            {personalInfo && !masterResume && <ModifyMasterResumeComponent onSetMasterResume={setMasterResume} />}
            {!llmResponse && masterResume && <SetJobPostingComponent masterResume={masterResume} onSetLLMResponse={setLlmResponse} />}
          </>
        )}
        {llmResponse && personalInfo && (
          <div className="flex flex-col min-h-screen">
            <div className="w-full bg-white/70 shadow-sm p-4">
              <div className="max-w-7xl mx-auto">
                {/* Desktop */}
                <div className="hidden lg:flex flex-row justify-between gap-4">
                  <div className="flex items-center gap-2">
                    <Button onClick={() => setIsCoverLetterOpen(true)} className="w-auto">
                      <Paperclip className="mr-2 h-4 w-4" /> Cover Letter
                    </Button>
                    <Button onClick={() => setIsIcebreakerMessageOpen(true)} className="w-auto">
                      <Flame className="mr-2 h-4 w-4" /> Ice Breaker
                    </Button>
                  </div>

                  <div className="flex items-center gap-2">
                    <div className="flex">
                      {' '}
                      <Select onValueChange={(value) => setResumeLayout(value as ResumeLayout)}>
                        <Label className="w-[150px]" style={{ lineHeight: '40px' }}>
                          Resume Layout
                        </Label>
                        <SelectTrigger className="w-full sm:w-[200px]">
                          <SelectValue placeholder={humanReadableResumeLayout} />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="reddit-recommended">Reddit (Recommended)</SelectItem>
                          <SelectItem value="skills-sidebar">Skills Sidebar</SelectItem>
                        </SelectContent>
                      </Select>
                    </div>
                    <Button onClick={updateResumeById} disabled={!changed || loading} variant={changed ? 'rainbowGlow' : 'default'} className="w-full">
                      {loading ? <Spinner className="mr-2 h-4 w-4" /> : <Save className="mr-2 h-4 w-4" />} Save
                    </Button>
                  </div>
                </div>
                {/* Mobile */}
                <div className="lg:hidden flex flex-col gap-2">
                  <div className="flex items-center gap-2">
                    <Button variant={getButtonVariant()} onClick={() => setIsCoverLetterOpen(true)} className="w-full">
                      <Paperclip className="mr-2 h-4 w-4" /> Cover Letter
                    </Button>
                    <Button variant={getButtonVariant()} onClick={() => setIsIcebreakerMessageOpen(true)} className="w-full">
                      <Flame className="mr-2 h-4 w-4" /> Ice Breaker
                    </Button>
                  </div>
                  <div className="flex items-center gap-2">
                    <div className="flex">
                      <Select onValueChange={(value) => setResumeLayout(value as ResumeLayout)}>
                        <Label className="w-8 flex items-center justify-center" style={{ lineHeight: '40px' }}>
                          <Layout className="h-4 w-4" />
                        </Label>
                        <SelectTrigger className="w-[70vw]">
                          <SelectValue placeholder={humanReadableResumeLayout} />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="reddit-recommended">Reddit (Recommended)</SelectItem>
                          <SelectItem value="skills-sidebar">Skills Sidebar</SelectItem>
                        </SelectContent>
                      </Select>
                    </div>
                    <Button onClick={updateResumeById} disabled={!changed || loading} variant={changed ? 'rainbowGlow' : 'default'} className="w-auto">
                      {loading ? <Spinner className="h-4 w-4" /> : <Save className="h-4 w-4" />}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-grow overflow-hidden p-4">
              <div className="max-w-7xl mx-auto h-full">
                {document &&
                  (isMobile ? (
                    <PDFDownloadLink document={document} fileName={getFileName()}>
                      {({ blob, url, loading, error }) =>
                        loading ? (
                          'Loading document...'
                        ) : (
                          <>
                            <h2 className="text-2xl mb-4">Your resume is ready!</h2>
                            <Button variant="rainbowGlow" className="w-full justify-center">
                              <Download className="inline mr-2 h-4 w-4" />
                              <span>Download PDF</span>
                            </Button>
                          </>
                        )
                      }
                    </PDFDownloadLink>
                  ) : (
                    <PDFViewer className="w-full h-full" style={{ minHeight: '400px' }}>
                      {document}
                    </PDFViewer>
                  ))}
              </div>
            </div>
            <CoverLetterComponent isVisible={isCoverLetterOpen} setIsVisible={setIsCoverLetterOpen} response={llmResponse} />
            <IceBreakerComponent isVisible={isIcebreakerMessageOpen} setIsVisible={setIsIcebreakerMessageOpen} response={llmResponse} />
          </div>
        )}
      </main>
    </div>
  );
};

export default ResumeBuilder;
