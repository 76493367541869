import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { InputWithLabel } from '@/components/ui/input-with-label';
import { DragDropContext, Draggable, Droppable, DropResult } from '@hello-pangea/dnd';
import ModalComponent from '@pages/components/ModalComponent';
import { Label } from '@radix-ui/react-label';
import { Education } from '@schema/llm-response.interface';
import { GraduationCap, GripVertical, Trash, X } from 'lucide-react';
import { useEffect, useState } from 'react';

const EducationItem = ({
  index,
  item,
  onRemove,
  onChange,
  onEditing,
}: {
  /**
   * The index of the education item.
   */
  index: number;

  /**
   * The education item.
   */
  item: Education;

  /**
   * Occurs when the user clicks the remove button on the education item.
   *
   * @param {number} index The index of the education item.
   * @returns {void}
   */
  onRemove: (index: number) => void;

  /**
   * Occurs when the user changes a value in the education item.
   *
   * @param {number} index The index of the education item.
   * @param {string} key The key of the value to change.
   * @param {string | string[]} value The new value of the key.
   * @returns {void}
   */
  onChange: (index: number, key: string, value: string | string[]) => void;

  /**
   * Occurs when the user starts or stops editing the education item.
   *
   * @param {boolean} editing Whether the user is editing the education item.
   * @returns {void}
   */
  onEditing: (editing: boolean) => void;
}) => {
  const [editing, setEditing] = useState(false);

  /**
   * Notify the parent component when the user starts or stops editing the experience item
   */
  useEffect(() => {
    onEditing(editing);
  }, [editing, onEditing]);

  /**
   * Removes an honor from the education item.
   *
   * @param {number} i The index of the honor to remove.
   */
  const removeHonors = (i: number) => {
    const updatedHonors = [...item.honors];
    updatedHonors.splice(i, 1);
    onChange(index, 'honors', updatedHonors);
  };

  /**
   * Adds an honor to the education item.
   */
  const addHonors = () => {
    const updatedHonors = [...item.honors, ''];
    onChange(index, 'honors', updatedHonors);
  };

  /**
   * Edits an honor in the education item.
   *
   * @param {number} i The index of the honor to edit.
   * @param {string} value The new value of the honor.
   */
  const editHonors = (i: number, value: string) => {
    const updatedHonors = [...item.honors];
    updatedHonors[i] = value;
    onChange(index, 'honors', updatedHonors);
  };

  /**
   * Rearranges the honors when the user drags and drops an item.
   *
   * @param {DropResult} result The result of the drag and drop operation.
   * @returns {void}
   */
  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const updatedHonors = [...item.honors];
    const [reorderedItem] = updatedHonors.splice(result.source.index, 1);
    updatedHonors.splice(result.destination.index, 0, reorderedItem);
    onChange(index, 'honors', updatedHonors);
  };

  return (
    <Card key={index}>
      {!editing ? (
        <CardHeader>
          <CardTitle className="flex justify-start gap-2">{item.degree}</CardTitle>
          <CardDescription className="flex justify-between">
            <span>{item.school}</span>
            <span>{item.date}</span>
          </CardDescription>
          <CardDescription>{item.major}</CardDescription>
        </CardHeader>
      ) : (
        <>
          <CardHeader>
            <CardTitle className="flex justify-start gap-2">Education #{index + 1}</CardTitle>
          </CardHeader>
          <CardContent className="space-y-2">
            <InputWithLabel label={`Degree`} inline value={item.degree} onChange={(e) => onChange(index, 'degree', e.target.value)} />
            <InputWithLabel label={`Major`} inline value={item.major} onChange={(e) => onChange(index, 'major', e.target.value)} />
            <InputWithLabel label={`School`} inline value={item.school} onChange={(e) => onChange(index, 'school', e.target.value)} />
            <InputWithLabel label={`Date`} inline value={item.date} onChange={(e) => onChange(index, 'date', e.target.value)} />
            {item.honors.length > 0 && (
              <div className="">
                <Label htmlFor={`education-${index}`} className="font-medium">
                  Honors
                </Label>

                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="honors-list">
                    {(provided) => (
                      <div className="space-y-2" {...provided.droppableProps} ref={provided.innerRef}>
                        {item.honors.map((honor, i) => (
                          <Draggable key={i} draggableId={i.toString()} index={i}>
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{ ...provided.draggableProps.style, left: 'auto !important', top: 'auto !important' }}>
                                <div key={i} className="flex justify-start items-center space-x-2">
                                  <GripVertical className="h-6 w-6 hover:cursor-grab" />
                                  <Input id={`education-${index}-honor-${i}`} name={`education-${index}-honor-${i}`} value={honor} onChange={(e) => editHonors(i, e.target.value)} />
                                  <Button className="px-2" onClick={() => removeHonors(i)}>
                                    <X className="h-6 w-6" />
                                  </Button>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            )}
          </CardContent>
        </>
      )}

      <CardFooter className="flex justify-between bg-gray-200 p-2">
        <Button variant="link" size="icon" onClick={() => onRemove(index)}>
          <Trash className="h-6 w-6" />
        </Button>
        <div className="space-x-2">
          {editing && (
            <Button variant="default" onClick={addHonors}>
              Add Honors
            </Button>
          )}
          <Button variant="default" onClick={() => setEditing(!editing)}>
            {editing ? 'Done' : 'Edit'}
          </Button>
        </div>
      </CardFooter>
    </Card>
  );
};

interface ModifyEducationComponentProps {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  items: Education[];
  onSet: (education: Education[]) => void;
}

const ModifyEducationComponent = ({ isVisible, setIsVisible, items, onSet }: ModifyEducationComponentProps) => {
  const [editing, setEditing] = useState(false);
  const [education, setEducation] = useState<Education[]>([]);

  /**
   * Load the education items from the items prop when the component mounts.
   */
  useEffect(() => {
    setEducation(items);
  }, [items]);

  /**
   * Adds a new education item to the list.
   *
   * @returns {void}
   */
  const addEducation = () => {
    setEducation([...education, { degree: '', major: '', school: '', date: '', honors: [] }]);
  };

  /**
   * Removes an education item from the list.
   *
   * @param {number} index The index of the education item to remove.
   * @returns {void}
   */
  const removeEducation = (index: number) => {
    const updatedEducation = [...education];
    updatedEducation.splice(index, 1);
    setEducation(updatedEducation);
  };

  /**
   * Updates a value in an education item.
   *
   * @param {number} index The index of the education item.
   * @param {string} key The key of the value to change.
   * @param {string | string[]} value The new value of the key.
   * @returns {void}
   */
  const handleEducationChange = (index: number, key: string, value: string | string[]) => {
    const updatedEducation = [...education];
    updatedEducation[index][key] = value;
    setEducation(updatedEducation);
  };

  /**
   * Submits the education items to the parent component.
   *
   * @returns {void}
   */
  const handleSubmit = () => {
    onSet(education);
    setIsVisible(false);
  };

  /**
   * Rearranges the education items when the user drags and drops an item.
   *
   * @param {DropResult} result The result of the drag and drop operation.
   * @returns {void}
   */
  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const updatedEducation = [...education];
    const [reorderedItem] = updatedEducation.splice(result.source.index, 1);
    updatedEducation.splice(result.destination.index, 0, reorderedItem);

    setEducation(updatedEducation);
  };

  return (
    <ModalComponent
      title="Your Education"
      subtitle="Add, edit, or remove your education that will appear on your resume."
      isModalOpen={isVisible}
      setIsModalOpen={setIsVisible}
      onSubmit={handleSubmit}
      submitBtnText="Save Education"
      loading={false}
      extraButtons={
        <Button variant="default" onClick={addEducation}>
          Add Another
        </Button>
      }
      icon={<GraduationCap className="h-6 w-6" />}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="education-list">
          {(provided) => (
            <div className="space-y-2" {...provided.droppableProps} ref={provided.innerRef} style={{ maxHeight: '50vh', overflowY: 'auto' }}>
              {education.map((item, index) => (
                <Draggable key={index} draggableId={index.toString()} index={index} isDragDisabled={editing}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{ ...provided.draggableProps.style, left: 'auto !important', top: 'auto !important' }}>
                      <EducationItem index={index} item={item} onRemove={removeEducation} onChange={handleEducationChange} onEditing={setEditing} />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </ModalComponent>
  );
};

export default ModifyEducationComponent;
