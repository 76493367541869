import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import useApi from '../hooks/useApi';
import { Resume } from '../schema/resume.interface';
import { useAuth } from './AuthContext';

interface HistoricalContextSchema {
  history: Resume[];
  setHistory: (history: Resume[]) => void;
  loading: boolean;
}

const HistoricalContext = createContext<HistoricalContextSchema>({
  history: [],
  setHistory: () => void 0,
  loading: false,
});

export const HistoryProvider = ({ children }: { children: ReactNode }) => {
  const { request } = useApi();
  const { user } = useAuth();
  const [history, setHistory] = useState<Resume[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getHistory = async () => {
      try {
        setLoading(true);
        const response: Resume[] = await request({ method: 'get', url: '/api/history' }).finally(() => setLoading(false));
        setHistory(response);
        return response;
      } catch (error: unknown) {
        console.error('Error getting resumes:', error);
      }
    };

    if (user) {
      getHistory();
    }
  }, [user, request]);

  return <HistoricalContext.Provider value={{ loading, history, setHistory }}>{children}</HistoricalContext.Provider>;
};

export const useHistory = () => useContext(HistoricalContext);
