import { Text, View } from '@react-pdf/renderer';
import { LLMResponse } from '@schema/llm-response.interface';
import styles from '../SharedStyles';
import BoldedText from './BoldedText';
import SpaceBetween from './SpaceBetween';

const MapWorkExperienceComponent = ({ response }: { response: LLMResponse }) => {
  const result: JSX.Element[] = response.experiences.map((exp, index) => (
    <View key={index}>
      <SpaceBetween col1={exp.company} col2={exp.date} fontSize={14} fontWeight={900} />
      <SpaceBetween col1={exp.title} col2={exp.location} fontSize={12} fontStyle={'italic'} />
      <Text style={styles.li}>
        {exp.responsibilities.map((resp, i) => (
          <BoldedText key={i} text={'• ' + resp} boldedWords={response.boldedWords} />
        ))}
      </Text>
    </View>
  ));

  return <>{result}</>;
};

export default MapWorkExperienceComponent;
