import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import braintree from 'braintree';
import React, { useEffect, useState } from 'react';
import BraintreeDropin from './BraintreeDropin';

interface ModifySubscriptionProps {
  plans: braintree.Plan[];
  subscription: braintree.Subscription | undefined;
  onSetSubscription: (subscription: braintree.Subscription) => void;
}

const ModifySubscription: React.FC<ModifySubscriptionProps> = ({ plans, subscription, onSetSubscription }: ModifySubscriptionProps) => {
  const [selectedPlan, setSelectedPlan] = useState<braintree.Plan | undefined>(undefined);

  /**
   * Get the features of the plan
   * 
   * @param {string} planId The ID of the plan
   * @returns {string[]} The features of the plan
   */
  const getPlanFeatures = (planId: string): string[] => {
    switch (planId) {
      case 'resume-rescue-basic':
        return ['100 tailored resumes', '100 cover letters', '100 icebreaker messages'];
      case 'resume-rescue-unlimited':
        return ['Unlimited tailored resumes!', 'Unlimited cover letters!', 'Unlimited icebreaker messages!'];
      default:
        return ['Unknown plan'];
    }
  };

  /**
   * Set the selected plan when the subscription changes
   */
  useEffect(() => {
    if (subscription) {
      setSelectedPlan(plans.find((plan) => plan.id === subscription.planId));
    }
  }, [subscription, plans]);

  /**
   * Handle the plan change event
   * 
   * @param {string} planId The ID of the plan
   * @returns {void}
   */
  const handlePlanChange = (planId: string) => {
    const plan = plans.find((p) => p.id === planId);
    if (plan) setSelectedPlan(plan);
  };

  // Get the current plan
  const currentPlan = subscription ? plans.find((plan) => plan.id === subscription.planId) : undefined;

  return (
    <>
      <h1 className="text-3xl font-bold mb-6">Choose Your Subscription Plan</h1>

      <div className="grid grid-cols-2 gap-6 mb-8">
        {plans.map((plan) => (
          <Card key={plan.id} className={`${selectedPlan?.id === plan.id ? 'border-blue-500 border-2' : ''}`}>
            <CardHeader>
              <CardTitle>{plan.name}</CardTitle>
              <CardDescription>{plan.description}</CardDescription>
              <CardDescription>${plan.price}/month</CardDescription>
            </CardHeader>
            <CardContent>
              <ul className="list-disc pl-5">
                {getPlanFeatures(plan.id).map((feature, index) => (
                  <li key={index} className="text-gray-600">
                    {feature}
                  </li>
                ))}
              </ul>
            </CardContent>
            <CardFooter>
              {currentPlan === plan ? (
                <Button disabled className='w-full'>
                  Current
                </Button>
              ) : (
                <Button variant={'rainbowGlow'} className='w-full' onClick={() => handlePlanChange(plan.id)}>
                  Select Plan
                </Button>
              )}
            </CardFooter>
          </Card>
        ))}
      </div>

      <Card className='w-1/2 mx-auto'>
        <CardHeader>
          <CardTitle>Payment Information</CardTitle>
          <CardDescription>Securely process your payment with Braintree</CardDescription>
        </CardHeader>
        <CardContent>{selectedPlan && <BraintreeDropin plan={selectedPlan} onHandleSubscribe={onSetSubscription} readyForPurchase={selectedPlan !== currentPlan} />}</CardContent>
      </Card>
    </>
  );
};

export default ModifySubscription;
