import { Button } from '@/components/ui/button';
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Spinner } from '@/components/ui/spinner';
import { useAuth } from '@context/AuthContext';
import { useError } from '@context/ErrorContext';
import { zodResolver } from '@hookform/resolvers/zod';
import useApi from '@hooks/useApi';
import { Save } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

const formSchema = z.object({
  name: z
    .string()
    .min(2, {
      message: 'Name must be at least 2 characters.',
    })
    .max(225, {
      message: 'Name must be at most 225 characters.',
    }),
  email: z.string().email({
    message: 'Please enter a valid email address.',
  }),
  // firstName: z
  //   .string()
  //   .min(2, {
  //     message: 'First name must be at least 2 characters.',
  //   })
  //   .max(225, {
  //     message: 'First name must be at most 225 characters.',
  //   }),
  // lastName: z
  //   .string()
  //   .min(2, {
  //     message: 'Last name must be at least 2 characters.',
  //   })
  //   .max(225, {
  //     message: 'Last name must be at most 225 characters.',
  //   }),
  phone: z
    .string()
    .max(20, {
      message: 'Phone number must be at most 20 characters.',
    })
    .nullable(),
  location: z
    .string()
    .min(2, {
      message: 'Location must be at least 2 characters.',
    })
    .nullable(),
  githubUrl: z.string().url().nullable(),
  linkedinUrl: z.string().url().nullable(),
});

/**
 * The payload used when updating a user's profile.
 */
export interface ProfileUpdatePayload {
  email?: string;
  name?: string;
  picture?: File | null;
  firstName?: string;
  lastName?: string;
  phone?: string;
  location?: string;
  githubUrl?: string;
  linkedinUrl?: string;
}

const Profile = () => {
  const { loading, request } = useApi();
  const { user, setUser } = useAuth();
  const { setError } = useError();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: user?.name ?? '',
      email: user?.email ?? '',
      // firstName: user?.firstName ?? '',
      // lastName: user?.lastName ?? '',
      phone: user?.phone ?? '',
      location: user?.location ?? '',
      githubUrl: user?.githubUrl ?? '',
      linkedinUrl: user?.linkedinUrl ?? '',
    },
  });

  /**
   * Saves the user's profile information to the server.
   */
  const handleOnSubmit = async (values: z.infer<typeof formSchema>) => {
    console.log(values);
    try {
      // Save master resume to server
      await request({
        url: '/api/users/personal-info',
        method: 'POST',
        data: values,
      });

      // Update the user object with the new personal info
      if (user) {
        setUser({ ...user, ...values });
      }
    } catch (error) {
      console.error(error);
      setError('Failed to save personal information. Please try again later.');
    }
  };

  /**
   * Updates the personal info state when the user types in the input fields.
   * @param e - The input change event.
   */
  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === 'phone') {
      const formattedPhoneNumber = formatPhoneNumber(value);
      form.setValue('phone', formattedPhoneNumber);
    }
  };

  /**
   * Formats the phone number as the user types it in.
   * @param phoneNumber - The phone number to format.
   * @returns The formatted phone number.
   */
  const formatPhoneNumber = (phoneNumber: string) => {
    const cleaned = phoneNumber.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return phoneNumber;
  };

  /**
   * Formats a name into a slug.
   * 
   * @param name - The name to be formatted.
   * @returns The formatted slug.
   */
  const formatNameToSlug = (name: string) => {
    return name
      .toLowerCase()
      .replace(/ /g, '-')
      .replace(/[^\w-]+/g, '');
  };

  return (
    <>
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="px-4 py-6 sm:px-0">
          <h1 className="text-3xl font-semibold">Your Resume Rescue Profile</h1>
          <p className="mt-2 text-sm text-gray-500">While Not sent directly to any 3rd party service, this information will be displayed on your generated resumes.</p>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(handleOnSubmit)} className="space-y-6">
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Your Name</FormLabel>
                    <FormControl>
                      <Input placeholder="Harry Potter" {...field} />
                    </FormControl>
                    <FormDescription>Your full name at the top of the resume.</FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <h2 className="text-2xl font-semibold mt-6">Your Contact Info</h2>

              <div className="space-y-2">
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Your Email Address</FormLabel>
                      <FormControl>
                        <Input placeholder="harry@underthestairs.com" {...field} />
                      </FormControl>
                      <FormDescription>Your email address</FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="phone"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Your Phone Number</FormLabel>
                      <FormControl>
                        <Input placeholder="(xxx) xxx-xxxx" {...field} value={field.value ?? ''} onChange={handlePhoneNumberChange} />
                      </FormControl>
                      <FormDescription>This is your public display name.</FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="location"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Your Location</FormLabel>
                      <FormControl>
                        <Input placeholder="Little Whinging, Surrey" {...field} value={field.value ?? ''} />
                      </FormControl>
                      <FormDescription>Just the city and abbreviated state will do.</FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="githubUrl"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>GitHub Profile URL</FormLabel>
                      <FormControl>
                        <Input placeholder={`https://github.com/${formatNameToSlug(user?.name ?? '')}`} {...field} value={field.value ?? ''} />
                      </FormControl>
                      <FormDescription>If you&apos;re a developer, you should probably provide your GitHub profile link.</FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="linkedinUrl"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>LinkedIn Profile URL</FormLabel>
                      <FormControl>
                        <Input placeholder={`https://www.linkedin.com/in/${formatNameToSlug(user?.name ?? '')}`} {...field} value={field.value ?? ''} />
                      </FormControl>
                      <FormDescription>If you have it, you may as well provide it. Just make sure it&apos;s updated with your latest information.</FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <Button type="submit" disabled={loading} variant={'rainbowGlow'}>
                <div className="flex items-center">
                  {loading ? <Spinner className="mr-2 h-4 w-4" /> : <Save className="mr-2 h-4 w-4" />} <span>Save</span>
                </div>
              </Button>
            </form>
          </Form>
        </div>
      </div>
    </>
  );
};

export default Profile;
