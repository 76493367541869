import { Button } from '@/components/ui/button';
import { useLinkedinAuth } from '@utils/linkedin/oAuth';

const ContinueWithLinkedIn = () => {
  const { initiateLinkedinAuth } = useLinkedinAuth();

  const login = async () => {
    await initiateLinkedinAuth();
  };

  return (
    <>
      <Button onClick={login} className="w-full flex flex-row justify-start align-middle bg-[#0077B5] text-white">
        <img className="mr-2" height="20" width="20" alt="Google" src="https://cdn.simpleicons.org/linkedin/white" />
        <span className="bg-white" style={{ width: '1px', height: '24px' }}></span>
        <span className="ml-2">Continue with LinkedIn</span>
      </Button>
    </>
  );
};

export default ContinueWithLinkedIn;
