import { InputWithLabel } from '@/components/ui/input-with-label';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import useApi from '@hooks/useApi';
import ModalComponent from '@pages/components/ModalComponent';
import { LLM, LLMSettings } from '@schema/llm-settings.interface';
import { Cpu } from 'lucide-react';
import React, { useEffect, useState } from 'react';

interface LLMSettingsComponentProps {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const LLMSettingsComponent: React.FC<LLMSettingsComponentProps> = ({ isVisible, setIsVisible }) => {
  const { loading, request } = useApi();
  const [settings, setSettings] = useState<LLMSettings>({ service: 'OpenAI', model: '', models: {} });

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        request({ method: 'GET', url: '/api/settings' }).then((response) => {
          const { service, models } = response;
          setSettings({ service, models, model: models[service.toLowerCase()] });
        });
      } catch (error) {
        console.error('Error fetching LLM settings:', error);
      }
    };

    fetchSettings();
  }, [request]);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    const { name, value } = e.target;
    setSettings((prevSettings) => ({ ...prevSettings, [name]: value }));
  };

  const handleSubmit = () => {
    try {
      request({
        method: 'POST',
        url: '/api/settings',
        data: {
          service: settings.service,
          model: settings.model,
        },
      }).then(() => {
        setIsVisible(false);
      });
    } catch (error) {
      console.error('Error saving LLM settings:', error);
    }
  };

  return (
    <ModalComponent
      title="LLM Settings"
      subtitle="Configure your LLM settings"
      isModalOpen={isVisible}
      setIsModalOpen={setIsVisible}
      onSubmit={handleSubmit}
      loading={loading}
      icon={<Cpu className="h-6 w-6" />}>
      <form onSubmit={handleSubmit} className="space-y-2">
        <Select
          name="llm"
          value={settings.service}
          onValueChange={(value: LLM) => {
            const model = settings.models[value.toLowerCase()];
            setSettings((prevSettings) => ({ ...prevSettings, service: value, model }));
          }}>
          <SelectTrigger className="w-full">
            <SelectValue placeholder="LLM Service" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="OpenAI">OpenAI</SelectItem>
            <SelectItem value="Groq">Groq</SelectItem>
            {process.env.APP_ENV !== 'production' && <SelectItem value="Ollama">Ollama</SelectItem>}
          </SelectContent>
        </Select>

        <InputWithLabel label="Model" name="model" value={settings.model} onChange={handleChange} type="text" />

        <p className="text-sm text-gray-500">
          Available models at{' '}
          {settings.service === 'OpenAI' && (
            <a href="https://platform.openai.com/models" target="_blank" rel="noreferrer">
              https://platform.openai.com/models
            </a>
          )}
          {settings.service === 'Groq' && (
            <a href="https://console.groq.com/docs/models" target="_blank" rel="noreferrer">
              https://console.groq.com/docs/models
            </a>
          )}
          {settings.service === 'Ollama' && (
            <a href="http://localhost:11434/api/tags" target="_blank" rel="noreferrer">
              http://localhost:11434/api/tags
            </a>
          )}
        </p>
      </form>
    </ModalComponent>
  );
};

export default LLMSettingsComponent;
