import { Brain, Copy, FileCheck, FileText, LucideIcon, Send } from 'lucide-react';
import React, { useEffect, useRef, useState } from 'react';

interface Step {
  icon: LucideIcon;
  title: string;
  description: string;
}

interface HowItWorksStepProps {
  icon: LucideIcon;
  title: string;
  description: string;
  isActive: boolean;
}

const HowItWorksStep = React.forwardRef<HTMLDivElement, HowItWorksStepProps>(({ icon: Icon, title, description, isActive }, ref) => {
  if (isActive) {
    return (
      <div ref={ref} className="flex items-start space-x-4 p-4 rounded-lg transition-all duration-300 shadow-md bg-white">
        <div className="h-12 w-12 flex items-center justify-center transition-all duration-300 rounded-full bg-blue-200 text-blue-600 overflow-hidden">
          <Icon size={24} />
        </div>
        <div>
          <h4 className="font-semibold text-lg mb-2">{title}</h4>
          <p className="text-gray-600">{description}</p>
        </div>
      </div>
    );
  } else {
    return (
      <div ref={ref} className="flex items-start space-x-4 p-4 rounded-lg transition-all duration-300 bg-gray-100">
        <div className="h-12 w-12 flex items-center justify-center rounded-full bg-gray-200 text-gray-600">
          <Icon size={24} />
        </div>
        <div>
          <h4 className="font-semibold text-lg mb-2">{title}</h4>
          <p className="text-gray-600">{description}</p>
        </div>
      </div>
    );
  }
});

HowItWorksStep.displayName = 'HowItWorksStep';

const HowItWorksSection: React.FC = () => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const stepRefs = useRef<(HTMLDivElement | null)[]>([]);

  const steps: Step[] = [
    {
      icon: FileText,
      title: "Set up your 'life resume'",
      description: 'Create a comprehensive work and education history.',
    },
    {
      icon: Copy,
      title: 'Copy job posting',
      description: 'Paste a job description from any job board.',
    },
    {
      icon: Brain,
      title: 'AI analysis',
      description: 'Our AI analyzes job requirements and your experience.',
    },
    {
      icon: FileCheck,
      title: 'Receive tailored materials',
      description: 'Get a personalized resume, cover letter, and icebreaker message.',
    },
    {
      icon: Send,
      title: 'Apply with confidence',
      description: 'Submit your application with tailored materials.',
    },
  ];

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight / 2;

      stepRefs.current.forEach((ref, index) => {
        if (ref && ref.offsetTop <= scrollPosition && ref.offsetTop + ref.offsetHeight > scrollPosition) {
          setActiveStep(index);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <section className="flex flex-col justify-center items-center min-h-screen py-16">
      <div className="w-full max-w-4xl">
        <h3 className="text-3xl font-bold mb-8 text-center">How It Works</h3>

        <div className="space-y-8">
          {steps.map((step, index) => (
            <HowItWorksStep key={index} ref={(el) => (stepRefs.current[index] = el)} icon={step.icon} title={step.title} description={step.description} isActive={index === activeStep} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default HowItWorksSection;
