import { cn } from '@/lib/utils';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-md ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'bg-primary text-primary-foreground hover:bg-primary/90',
        destructive: 'bg-destructive text-destructive-foreground hover:bg-destructive/90',
        outline: 'border border-input bg-background hover:bg-accent hover:text-accent-foreground',
        secondary: 'bg-secondary text-secondary-foreground hover:bg-secondary/80',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        link: 'text-primary underline-offset-4 hover:underline',
        rainbowGlow: 'relative text-white bg-gray-800',
      },
      size: {
        default: 'h-10 px-4 py-2 text-md font-medium',
        sm: 'h-5 rounded-md px-3 text-sm font-normal',
        lg: 'h-11 rounded-md px-8 text-lg font-black',
        icon: 'h-10 w-10',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({ className, variant, size, asChild = false, ...props }, ref) => {
  const Comp = asChild ? Slot : 'button';
  return (
    <Comp className={cn(buttonVariants({ variant, size, className }), 'group')} ref={ref} {...props}>
      {variant === 'rainbowGlow' && (
        <>
          <span
            className="absolute inset-[-4px] rounded-lg opacity-85 blur-sm transition-all duration-300 group-hover:opacity-100 group-hover:blur-md"
            style={{
              background: 'linear-gradient(45deg, #ff0000 0%, #ff9a00 10%, #d0de21 20%, #4fdc4a 30%, #3fdad8 40%, #2fc9e2 50%, #1c7fee 60%, #5f15f2 70%, #ba0cf8 80%, #fb07d9 90%, #ff0000 100%)',
              backgroundSize: '200% 200%',
              animation: 'rainbow-flow 2s linear infinite',
            }}
          />
          <span className="absolute inset-[-1px] rounded-[7px] bg-gray-800" />
          <span className="relative z-10">{props.children}</span>
        </>
      )}
      {variant !== 'rainbowGlow' && <>{props.children}</>}
    </Comp>
  );
});
Button.displayName = 'Button';

export { Button, buttonVariants };
