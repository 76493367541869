import React from 'react';
import { Input } from './input';
import { Label } from './label';

export type InputWithLabelProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  icon?: string;
  inline?: boolean;
};

const InputWithLabel = React.forwardRef<HTMLInputElement, InputWithLabelProps>(({ className, type, label = '', id, icon, inline, ...props }, ref) => {
  return (
    <div className={`w-full ${inline ? 'flex items-center' : 'grid items-center gap-1.5'}`}>
      {label && <Label htmlFor={id} className={inline ? 'mr-2' : ''}>{label}</Label>}
      <div className={`flex items-center ${inline ? 'flex-grow' : 'justify-center'}`}>
        {icon && <img src={icon} alt={label} className="mr-2 w-8 h-8 object-contain" />}
        <Input type={type} id={id} className={`${className} ${inline ? 'w-full' : ''}`} ref={ref} {...props} />
      </div>
    </div>
  );
});

InputWithLabel.displayName = 'InputWithLabel';

export { InputWithLabel };
