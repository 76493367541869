import { Link } from 'react-router-dom';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="text-white py-8 bg-black">
      <div className="container mx-auto px-4">
        <div className="w-3/4 mx-auto">
          <div className="grid md:grid-cols-2 gap-8">
            <div>
              <h4 className="font-bold mb-4">About</h4>
              <ul className="space-y-2">
                <li>
                  <Link to="/about" className="hover:underline">
                    About
                  </Link>
                </li>
                <li>
                  <Link to="/faq" className="hover:underline">
                    FAQ
                  </Link>
                </li>
                <li>
                  <Link to="/contact" className="hover:underline">
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <h4 className="font-bold mb-4">Legalese</h4>
              <ul className="space-y-2">
                <li>
                  <Link to="/termsofuse" className="hover:underline">
                    Terms of Use
                  </Link>
                </li>
                <li>
                  <Link to="/privacypolicy" className="hover:underline">
                    Privacy Policy
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto px-4 pt-6 text-center">
        <p>
          &copy; {currentYear} {process.env.REACT_APP_NAME}. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
