import { useAuth } from '@context/AuthContext';
import { useLoading } from '@context/LoadingContext';
import useApi from '@hooks/useApi';
import { useEffect } from 'react';

function Dashboard() {
  const { user } = useAuth();
  const { loading, request } = useApi();
  const { setIsLoading } = useLoading();

  /**
   * Set the loading state based on the API request.
   */
  useEffect(() => {
    setIsLoading(loading);
  }, [loading, setIsLoading]);

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div className="min-h-screen">
      <main>
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          <div className="px-4 py-6 sm:px-0">
            <h2 className="text-2xl font-semibold">Welcome, {user.firstName}!</h2>
            <p className="mt-1 text-sm text-gray-600">Here is that thing you asked for...</p>

            <div className="mt-6">
              <h3 className="text-lg font-medium">Admin Dashboard</h3>
            </div>

            <div className="mt-8"></div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Dashboard;
