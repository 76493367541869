import { Button } from '@/components/ui/button';
import { Spinner } from '@/components/ui/spinner';
import { Textarea } from '@/components/ui/textarea';
import { useAuth } from '@context/AuthContext';
import { useError } from '@context/ErrorContext';
import useApi from '@hooks/useApi';
import { Save } from 'lucide-react';
import { useEffect } from 'react';

interface ModifyMasterResumeComponentProps {
  onSetMasterResume: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const ModifyMasterResumeComponent = ({ onSetMasterResume }: ModifyMasterResumeComponentProps) => {
  const { user, setUser } = useAuth();
  const { loading, request } = useApi();
  const { setError } = useError();

  /**
   * Fetch the user's master resume when the component mounts.
   */
  useEffect(() => {
    if (user) {
      const masterResumeTextarea = document.getElementById('master-resume') as HTMLTextAreaElement;
      if (masterResumeTextarea) {
        masterResumeTextarea.value = user.masterResume ?? '';
      }
    }
  }, [user]);

  /**
   * Saves the master resume to the user's account.
   */
  const handleOnSubmit = async () => {
    const masterResumeTextarea = document.getElementById('master-resume') as HTMLTextAreaElement;

    if (!masterResumeTextarea) {
      setError('Master resume textarea is missing!');
      return;
    }

    try {
      await request({
        method: 'POST',
        url: '/api/users/master-resume',
        data: masterResumeTextarea.value,
        headers: {
          'Content-Type': 'text/plain',
        },
      }).then(() => {
        if (user) {
          setUser({ ...user, masterResume: masterResumeTextarea.value });
        }

        // Announce that the master resume has been set.
        onSetMasterResume(masterResumeTextarea.value);
      });
    } catch (error) {
      console.error(error);
      setError('Failed to save. Please try again later.');
    }
  };

  return (
    <div className="mt-6 space-y-4 bg-white p-8 shadow-lg rounded-lg flex flex-col flex-grow">
      <h2 className="text-2xl font-semibold">Your Master Resume</h2>
      <p className="text-sm text-gray-500">Fill in your entire work history, education, and skills below. This information will be used to generate tailored resumes using the job posting.</p>
      <div className="flex-grow flex">
        <Textarea id="master-resume" name="master-resume" className="h-full w-full" />
      </div>
      <Button onClick={handleOnSubmit} disabled={loading} className="w-full" variant={'rainbowGlow'}>
        <div className="flex items-center">{loading ? <Spinner className="mr-2 h-4 w-4" /> : <Save className="mr-2 h-4 w-4" />} Save</div>
      </Button>
    </div>
  );
};

export default ModifyMasterResumeComponent;
