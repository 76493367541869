import axios from 'axios';
import { useCallback } from 'react';

export const useGoogleAuth = () => {
  const initiateGoogleAuth = useCallback(async () => {
    try {
      const response = await axios.get<{ authUrl: string }>('/api/auth/google/auth');
      window.location.href = response.data.authUrl;
    } catch (error: unknown) {
      console.error('Error initiating Google Auth:', error);
    }
  }, []);

  return { initiateGoogleAuth };
};
