import { Button } from '@/components/ui/button';
import { InputWithLabel } from '@/components/ui/input-with-label';
import { useToast } from '@/components/ui/use-toast';
import { useAuth } from '@context/AuthContext';
import { useError } from '@context/ErrorContext';
import { useLoading } from '@context/LoadingContext';
import useApi from '@hooks/useApi';
import { CreditBundle } from '@pages/dashboard/CreditPurchase';
import { Purchase } from '@schema/purchase.interface';
import braintree from 'braintree';
import dropin from 'braintree-web-drop-in';
import { Check, X } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';

interface BraintreeDropinProps {
  plan?: braintree.Plan;
  bundle?: CreditBundle;
  readyForPurchase?: boolean;
  onHandlePurchase?: (purchase: Purchase) => void;
  onHandleSubscribe?: (subscription: braintree.Subscription) => void;
}

/**
 * Renders a component that integrates with Braintree Drop-in UI for handling payments.
 *
 * @component
 * @example
 * return (
 *   <BraintreeDropin bundle={bundle} onHandlePurchase={handlePurchase} />
 * )
 */
const BraintreeDropin: React.FC<BraintreeDropinProps> = ({ plan, bundle, readyForPurchase = true, onHandlePurchase, onHandleSubscribe }) => {
  const { user } = useAuth();
  const { loading, request } = useApi();
  const { setIsLoading } = useLoading();
  const { toast } = useToast();
  const { setError } = useError();
  const [braintreeInstance, setBraintreeInstance] = useState<dropin.Dropin | null>(null);
  const braintreeDropInTargetRef = useRef<HTMLDivElement>(null);
  const braintreeInitializedRef = useRef(false);
  const braintreeTeardownInProgressRef = useRef(false);
  const discountCodeRef = useRef<HTMLInputElement>(null);
  const [discount, setDiscount] = useState<braintree.Discount | null>(null);
  const [originalAmount, setOriginalAmount] = useState<number>(0);
  const [subtotal, setSubtotal] = useState<number>(0);

  /**
   * Updates the original amount when the bundle changes.
   */
  useEffect(() => {
    if (bundle) setOriginalAmount(bundle.price);
    if (plan) setOriginalAmount(parseFloat(plan.price));
    setSubtotal(originalAmount - parseFloat(discount?.amount ?? '0'));
  }, [bundle, plan, discount, originalAmount]);

  /**
   * Updates the loading state when the loading state changes.
   */
  useEffect(() => {
    setIsLoading(loading);
  }, [loading, setIsLoading]);

  /**
   * Initializes the Braintree Drop-in UI when the component mounts.
   */
  useEffect(() => {
    const initializeBraintree = async () => {
      // Prevent initializing Braintree if it's already initialized
      if (braintreeInitializedRef.current) return false;

      // Fetch the client token from the server
      const clientToken: string | boolean = await request({ method: 'get', url: '/api/payments/client-token' })
        .then((response) => {
          return response.clientToken;
        })
        .catch((err) => {
          setError(err.message);
          return false;
        });

      // If the clientToken is a boolean, an error occurred. Return false.
      if (typeof clientToken === 'boolean') return false;

      // The container element where the Drop-in UI will be mounted
      const container = braintreeDropInTargetRef.current;

      // If the Drop-in UI hasn't been initialized yet and the container is empty, initialize it
      if (braintreeInstance === null && container && container.children.length === 0 && clientToken) {
        console.log('Initializing Braintree Drop-in UI...');

        // Create the Drop-in UI instance
        return await dropin
          .create({
            authorization: clientToken,
            container: container as HTMLElement,
          })
          .then((dropin) => {
            setBraintreeInstance(dropin);
            braintreeInitializedRef.current = true;
            return true;
          })
          .catch((err) => {
            console.error(err);
            return false;
          });
      }
      return false;
    };

    // Initialize Braintree Drop-in UI
    initializeBraintree();
  }, [braintreeInstance, request, setError]);

  /**
   * Tears down the Braintree instance when the component unmounts.
   */
  useEffect(() => {
    return () => {
      if (braintreeInstance && !braintreeTeardownInProgressRef.current) {
        console.log('Tearing down Braintree instance....');
        braintreeTeardownInProgressRef.current = true;
        braintreeInstance
          .teardown()
          .then(() => {
            setBraintreeInstance(null);
            braintreeInitializedRef.current = false;
            braintreeTeardownInProgressRef.current = false;
          })
          .catch((err) => {
            console.error('Error tearing down Braintree instance:', err);
          });
      }
    };
  }, [braintreeInstance, braintreeTeardownInProgressRef]);

  /**
   * Handles the purchase process by requesting a payment method from the Braintree instance,
   * sending the payment nonce to the server, and updating the user's credit balance if the purchase is successful.
   */
  const handlePurchase = async () => {
    if (!braintreeInstance) {
      setError('Braintree instance not initialized');
      return;
    }
    if (!bundle) {
      setError('Bundle not selected');
      return;
    }

    try {
      const { nonce } = await braintreeInstance.requestPaymentMethod();
      const purchase: Purchase = await request({
        url: '/api/payments/process-payment',
        method: 'POST',
        data: {
          paymentMethodNonce: nonce,
          amount: bundle.price,
          credits: bundle.credits,
          discountId: discount?.id,
        },
      });

      if (purchase.credits) {
        if (purchase.credits && user) user.credits += purchase.credits;
        toast({
          title: 'Purchase successful!',
          description: `Successfully purchased ${purchase.credits} credits! You now have ${user?.credits || 0} credits!`,
        });

        if (onHandlePurchase) onHandlePurchase(purchase);
        // alert(`Successfully purchased ${selectedBundle.credits} credits!`);
        // Update user's credit balance in your app state
      } else {
        setError('Payment failed');
      }
    } catch (error) {
      console.error('Error processing payment:', error);
      setError('There was an error processing your payment. Please try again.');
    }
  };

  const handleSubscribe = async () => {
    if (!braintreeInstance) {
      setError('Braintree instance not initialized');
      return;
    }
    if (!plan) {
      setError('Plan not selected');
      return;
    }

    try {
      const { nonce } = await braintreeInstance.requestPaymentMethod();
      const subscription: braintree.Subscription = await request({
        url: '/api/payments/subscribe',
        method: 'POST',
        data: {
          paymentMethodNonce: nonce,
          planId: plan.id,
          discountId: discount?.id,
        },
      });

      if (subscription.id) {
        toast({
          title: 'Purchase successful!',
          description: `Successfully subscribed to ${plan.name}!`,
        });

        if (onHandleSubscribe) onHandleSubscribe(subscription);
        // alert(`Successfully purchased ${selectedBundle.credits} credits!`);
        // Update user's credit balance in your app state
      } else {
        setError('Payment failed');
      }
    } catch (error) {
      console.error('Error processing payment:', error);
      setError('There was an error processing your payment. Please try again.');
    }
  };

  /**
   * Checks the discount code entered by the user.
   */
  const checkDiscountCode = async () => {
    if (!discountCodeRef.current) return;

    const discountCode = discountCodeRef.current.value;
    if (!discountCode) return;

    const response: braintree.Discount & { message: string } = await request({ url: '/api/payments/check-discount-code', method: 'POST', data: { code: discountCode } });
    console.log(response);

    // If the response contains a message, display it as an error
    if (response.message) {
      setError(response.message);
      return;
    }

    // If the response contains an ID, set the discount and update the subtotal
    if (response.id) {
      setDiscount(response);
    }
  };

  return (
    <>
      <div ref={braintreeDropInTargetRef} id="braintree-drop-in-container"></div>

      <div className="flex flex-row items-end gap-2 my-4">
        <InputWithLabel ref={discountCodeRef} label="Discount Code" disabled={!!discount} />
        <Button size={'icon'} disabled={loading || !!discount} onClick={checkDiscountCode}>
          <Check size={16} />
        </Button>
      </div>

      <div className="border-t border-b border-gray-100 mb-4">
        <dl className="divide-y divide-gray-100">
          <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-normal leading-6 text-gray-700">Original Amount</dt>
            <dd className="text-right font-medium mt-1 text-sm leading-6 text-gray-900 sm:col-span-2 sm:mt-0">${originalAmount.toFixed(2)}</dd>
          </div>
          {discount !== null && (
            <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-normal leading-6 text-gray-700 flex items-center">Discount ({discount.name})</dt>
              <dd className="text-right font-medium mt-1 text-sm leading-6 text-gray-900 sm:col-span-2 sm:mt-0">
                ${discount.amount}
                <Button size={'icon'} className='text-sm ml-2' disabled={loading} onClick={() => setDiscount(null)}>
                  <X size={14} />
                </Button>
              </dd>
            </div>
          )}
          <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-normal leading-6 text-gray-700">Subtotal</dt>
            <dd className="text-right font-medium mt-1 text-sm leading-6 text-gray-900 sm:col-span-2 sm:mt-0">${parseFloat(subtotal.toString()).toFixed(2)}</dd>
          </div>
        </dl>
      </div>

      {bundle && (
        <Button className="w-full" variant={'rainbowGlow'} onClick={handlePurchase} disabled={loading || !braintreeInstance || !readyForPurchase}>
          {loading ? 'Processing...' : 'Purchase'}
        </Button>
      )}

      {plan && (
        <Button className="w-full" variant={'rainbowGlow'} onClick={handleSubscribe} disabled={loading || !braintreeInstance || !readyForPurchase}>
          {loading ? 'Processing...' : 'Subscribe'}
        </Button>
      )}
    </>
  );
};

export default BraintreeDropin;
